import ConfigurationAPI from "../Apis/ConfigurationAPI";

export const getStoredConfiguration = () => {
    const configuration = JSON.parse(localStorage.getItem("configuration"));
    return configuration || null;
}

export const getTenantCode = () => {
    const configuration = getStoredConfiguration();
    return configuration?.data?.current?.id
}

// used to auto add applicant card when preference is not empty
const AUTOFILL_APPLICANT_BY_MUNICIPALITY = {
    "B214": true
}

const ENABLED_FIELDS_BY_MUNICIPALITY = {
    "B214": ["data_di_nascita", "luogo_di_nascita"]
}

// check if PopupTextField is always enabled ignoring disabled status
export const isFieldAlwaysEnabled = (id) => {
    const municipalityCode = getTenantCode();

    return ENABLED_FIELDS_BY_MUNICIPALITY?.[municipalityCode]?.includes(id) || false;
};

export function updateLink(selector, newUrl) {
    const linkElement = document.querySelector(selector);
    if (linkElement) {
      linkElement.href = newUrl;
    }
}

const getQueryParam = (id) => {
    const queryParams = new URLSearchParams(window.location.search); // Ottiene i query params dall'URL attuale
    return queryParams.get(id); // Restituisce il valore del parametro o null se non esiste
  }


export const updateAppointmentUrl = () => {
    // window.scrollTo(0,0);
    const configuration = getStoredConfiguration();
    // console.log("///// updateAppointmentUrl", configuration, configuration?.data?.appointment_configuration)
    if(configuration?.data?.appointment_configuration && configuration?.data?.current?.reasons){
        let pathname = window.location.pathname.slice(1);
        const {appointment_configuration, current: {services, reasons}} = configuration?.data;
        if(pathname === "payment-page" && getQueryParam("service")){
            pathname = getQueryParam("service");
        }
        const currentService = services?.find(service => service?.path === pathname);
        // if(currentService)
        const serviceConfig = reasons?.find(reason => (reason?.title === currentService?.reason_title && currentService?.similarity > 80));
        let appointmentUrl;
        if(currentService && serviceConfig){
            const {base_url, service_url_param, office_url_param} = appointment_configuration;
            const hasParams = base_url?.indexOf("?") !== -1;
            appointmentUrl = `${base_url}${!hasParams ? "?" : ""}${service_url_param}=${serviceConfig.id}&${office_url_param}=${serviceConfig.office_id}&from=servizi`;
            
        }
        else {
            const {base_url} = appointment_configuration;
            appointmentUrl = base_url;
        }

        if(appointmentUrl){
            updateLink("#menu-info-1 > li:nth-child(2) > a", appointmentUrl);
            updateLink("#root > div > div:nth-child(4) > div > div.bg-grey-card > div > div > div > div > div > div > ul:nth-child(2) > li:nth-child(4) > a", appointmentUrl);
            updateLink("#root > div > div:nth-child(4) > div > div.bg-grey-card > div > div > div > div > div > div > ul:nth-child(2) > li:nth-child(3) > a", appointmentUrl);
        }
        // console.log("appointmentUrl", appointmentUrl, currentService, serviceConfig)
        // console.log("updateAppointmentUrl", appointmentUrl);
    }
}


export const formatCheckboxValue = (value) => {
    const separator = "-";
    const separatorIndex = value?.indexOf(separator);
    if(separatorIndex <= 3)
        return value?.slice(separatorIndex + 1);
    return value
}


export const getConfiguration = async (token, url) => {
    const abortController = new AbortController();
    let signal = abortController.signal;

    try {
        console.log("getting configuration")
        const configResponse = await ConfigurationAPI.getConfiguration(signal, token, url);
        localStorage.setItem("configuration", JSON.stringify(configResponse));
        // console.log("configResponse", configResponse)
    } catch (err) {
      console.log("Configuration error", err);
    }

    return abortController;
}

const EXTNERNAL_SERVICES = [
    {
        path: "/mensa-scolastica",
        id: "RMS"
    }
]
export const getServicePath = () => {
    const configuration = getStoredConfiguration();
    // console.log("configuration", configuration);
    let destination = localStorage.getItem('destination');
    if(configuration?.data?.current?.services){
        const services = configuration?.data?.current?.services || [];
        const service = EXTNERNAL_SERVICES.find(el => el.path === destination);
        if(service){
            const configService = services?.find(el => el?.id === service?.id);
            if(configService?.path?.indexOf("http") !== -1){
                destination = configService?.path;
            }
        }
    }
    return destination;
}

// https://servizi.montegrossopianlatte.nemea.cloud/