import React, { useEffect, useState } from "react";
import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";
import TabOpener from "../ui-el/TabOpener";
import PopupSelectField from "../ui-el/PopupSelectField";
import { onDataChange2, onSelectData2 } from "../../util/Dynamics";
import { ImuApi } from "../../Apis/ImuApi";


function DATPropertyEditPopup(props) {
    const [_, setSelection] = useState("");

    const [propertyData, setPropertyData] = useState({
        dati_immobile: {
            anno: props?.year || props?.registryData?.dati_immobile?.anno,
            tipologia_immobile: props?.registryData?.dati_immobile?.tipologia_immobile,
            catastale: props?.registryData?.dati_immobile?.catastale,
            rendita_catastale: props?.registryData?.dati_immobile?.rendita_catastale,
            percentuale_di_possesso: props?.registryData?.dati_immobile?.percentuale_di_possesso,
            numero_conviventi: props?.registryData?.dati_immobile?.numero_conviventi,
            immobile_variato: props?.registryData?.dati_immobile?.immobile_variato,
            mese_inizio: props?.registryData?.dati_immobile?.mese_inizio,
            mese_fine: props?.registryData?.dati_immobile?.mese_fine,
        },
        id: props.registryData?.id
    });
    const [error, setError] = useState(false);

    const dynamicTitleFieldHandler = () => {
        let type = propertyData.dati_immobile.tipologia_immobile;

        if (type.includes("14"))
            return "Valore mercato superficie"

        if (type.includes("15")
            || type.includes("16")
            || type.includes("17")
            || type.includes("1178"))
            return "Reddito dominicale";

        return "Rendita catastale";
    }


    const save = () => {
        // if(checkValidity(propertyData)) {
        // dispatcher(stepSlice.actions.dynamicEdit({id: "Immobili",internalID: props.immData.id,data: propertyData}));
        props.update({
            id: "immobili",
            data: propertyData,
            edit: true
        });

        // }
        // else
        //     setError(true);
    }

    // console.log("property data", propertyData, propertyData?.dati_comunali?.comune_montano === "si")
    return (
        <PopupBox close={props.close} save={save} title="Immobile">
            {
                error &&
                <p style={{ color: "red", fontWeight: "bolder" }}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
            }
            <PopupSelectField onChange={(e) => { onSelectData2(e, "dati_immobile", setPropertyData, setSelection) }}
                defaultValue={propertyData?.dati_immobile.tipologia_immobile}
                id="tipologia_immobile" fieldTitle={"Categoria tipologia immobile"}
                options={
                    [
                        "0 - Abitazione principale e assimilate (Cat. da A2 ad A7) e pertinenze (C/2, C/6, C/7)",
                        "1 - Abitazione principale di pregio (Cat. A/1, A/8, A/9) e pertinenze (C/2, C/6, C/7)",
                        "24 - Abitazione concessa in comodato gratuito a condizioni di Legge (riduzione 50% base imponibile)",
                        "2 - Abitazioni assegnate dagli Istituti Autonomi Case Popolari (ex IACP/ARES/ALER)",
                        "25 - Immobili locati a canone concordato (riduzione al 75%)",
                        "3 - Altre abitazioni - immobili Cat. A (tranne A/10)",
                        "4 - Cat. A/10 - Uffici e studi privati",
                        "5 - Cat. C/1 - Negozi e botteghe",
                        "6 - Cat. C/2 - Magazzini e locali di deposito",
                        "7 - Cat. C/3 - Laboratori per arti e mestieri",
                        "8 - Cat. B, C/4, C/5 - Fabbricati comuni",
                        "9 - Cat. C/6, C/7 - Stalle, scuderie, rimesse, autorimesse - Tettoie",
                        "10 - Cat. D, tranne D/5 e D/10 - Immobili industriali e commerciali",
                        "11 - Cat. D/5 - Istituti di credito ed assicurazioni",
                        "12 - Fabbricati rurali ad uso strumentale allattivita agricola (D/10)",
                        "13 - Fabbricati rurali ad uso strumentale allattivita agricola (Cat. A, C/2, C/6, C/7)",
                        "20 - Beni Merce - Realizzati da Imprese Edili, destinati alla vendita e rimasti invenduti",
                        "14 - Aree fabbricabili",
                        "15 - Terreni",
                        "16 - Terreni agricoli",
                        "17 - Terreni agricoli posseduti e condotti da operatori iscritti a previdenza agricola",
                        "1178 - Terreni agricoli in categoria OR.IR.FI. e ROSETO esclusi i coltivatori diretti o imprenditori agricoli professionali iscritti alla previdenza agricola"
                    ]
                }
                disabled={!propertyData?.dati_immobile?.anno}
            />
            <PopupSelectField onChange={(e) => { onSelectData2(e, "dati_immobile", setPropertyData, setSelection) }}
                id="catastale" fieldTitle={"Categoria catastale"} defaultValue={propertyData?.dati_immobile.catastale}
                disabled={propertyData?.dati_immobile?.tipologia_immobile.includes("20") ? false : true}
                description={propertyData.dati_immobile.tipologia_immobile.includes("20") ? "" : " "}
                options={
                    [
                        "3 - Altre abitazioni - immobili Cat. A (tranne A/10)",
                        "4 - Cat. A/10 - Uffici e studi privati",
                        "5 - Cat. C/1 - Negozi e botteghe",
                        "6 - Cat. C/2 - Magazzini e locali di deposito",
                        "7 - Cat. C/3 - Laboratori per arti e mestieri",
                        "8 - Cat. B, C/4, C/5 - Fabbricati comuni",
                        "9 -  Cat. C/6, C/7 - Stalle, scuderie, rimesse, autorimesse - Tettoie",
                        "10 -  Cat. D, tranne D/5 e D/10 - Immobili industriali e commerciali",
                        "11 - Cat. D/5 - Istituti di credito ed assicurazioni",
                    ]
                } />
            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                defaultValue={propertyData?.dati_immobile.numero_conviventi} id="numero_conviventi"
                fieldType="number" fieldTitle="Altri proprietari conviventi"
                disabled={false} />
            <PopupTextField
                onChange={(e) => onDataChange2(e, setPropertyData)}
                defaultValue={propertyData?.dati_immobile.rendita_catastale}
                fieldType="number"
                fieldTitle={`${dynamicTitleFieldHandler()}`}
                id={`rendita_catastale`}
                itr="dati_immobile" />
            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                defaultValue={propertyData?.dati_immobile.percentuale_di_possesso} id="percentuale_di_possesso"
                fieldType="number" fieldTitle="Percentuale di possesso" itr="dati_immobile" />
            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                defaultValue={propertyData?.dati_immobile.mese_inizio} id="mese_inizio"
                fieldType="date" fieldTitle="Mese inizio possesso" itr="dati_immobile" />
            <PopupTextField onChange={(e) => onDataChange2(e, setPropertyData)}
                defaultValue={propertyData?.dati_immobile.mese_fine} id="mese_fine"
                fieldType="date" fieldTitle="Mese fine possesso" itr="dati_immobile" />

        </PopupBox>
    )
}

export default DATPropertyEditPopup;