import React, { useState } from "react";
import { useSelector } from "react-redux";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import { useDispatch } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import Loader from "../../../UI-component/Loader";
import { formatCheckboxValue } from "../../../util";
import { useService } from "../../../classes/useService";
import { TaxBreaksService } from "../../../classes/taxBreaks/TaxBreakService";
import { uniqueIdBuilder } from "../../../util/Dynamics";

function Summary(props) {
  const {data, loading, error, values, save, savePrefs, getPrefs, update, updatePrefs, updateField, isValidStep} = useService(TaxBreaksService, props.save, null, props.send);
  
  const onNext = async () => {
    save(true);
  }

  const isRealyOut = (tipologia_immobile) => {
    const id = tipologia_immobile.split("-")[0].trim();
    if (id === "0" || id === "1" || id === "24" || id === " 25")
      return true;

    return false;
  }
  const getChoices = () => {
    const value = data?.tipologia;
    switch(value){
      case values?.types?.[0]?.value:
        return [
          // {
          //   "options": values.types || [],
          //   "title": [
          //     "DAT",
          //     "TIPOLOGIA"
          //   ],
          //   "type": "Select",
          //   "id": "tipologia"
          // },
          {
            "options": values.reasons || [],
            "title": [
              "DAT",
              "MOTIVO"
            ],
            "type": "Select",
            "id": "motivo"
          }
        ]
      case values?.types?.[1]?.value:
        return [
          {
            "options": values.seasonalReductions || [],
            "title": [
              "DAT",
              "riduzione_attivita_stagionali"
            ],
            "type": "Select",
            "id": "riduzione_attivita_stagionali",
            "customTitle": "Riduzione per attività stagionali o ad uso non continuativo"
          },
          {
            "options": values.lowerLevelsReductions || [],
            "title": [
              "DAT",
              "riduzione_livelli_inferiori"
            ],
            "type": "Select",
            "id": "riduzione_livelli_inferiori",
            "customTitle": "Riduzione per livelli inferiori di prestazione del servizio"

          },
          {
            "options": values.wasteProductionReductions || [],
            "title": [
              "DAT",
              "riduzione_produzione_rifiuti"
            ],
            "type": "Select",
            "id": "riduzione_produzione_rifiuti",
            "customTitle": "Riduzione per produzione di rifiuti non conferibili al pubblico servizio"

          },
          {
            "options": [
              {
                "id": 1,
                "title": "superficie_rifiuti"
              }
            ],
            "title": [
              "DAT",
              "superficie_rifiuti"
            ],
            "child_title": [],
            "type": "MultiText",
            "id": "superficie_rifiuti",
            "customTitle": "Superficie di formazione dei rifiuti o sostanze"
          },
          {
            "options": [
              {
                "id": 1,
                "title": "uso_tipologie_rifiuti"
              }
            ],
            "title": [
              "DAT",
              "uso_tipologie_rifiuti"
            ],
            "child_title": [],
            "type": "MultiText",
            "id": "uso_tipologie_rifiuti",
            "customTitle": "Uso e tipologie di rifiuti prodotti"
          }
        ]
      case values?.types?.[2]?.value:
        return [
          {
            "options": values.reductions || [],
            "title": [
              "DAT",
              "riduzione"
            ],
            "type": "Select",
            "id": "riduzione"
          },
        ]
      default:
        return null;
    }
  }

  const renderPrefs = () => {
    const value = data?.tipologia;
    switch(value){
      case "Imposta Municipale Propria (IMU)":
        return (
          <>
            <FormBoxField title="Tipologia" value={data.tipologia || "Non compilato"} />
            <FormBoxField title="Motivo" value={data.motivo || "Non compilato"} />
          </>
        )
      case "Tassa sui Rifiuti (TARI) - utenze non domestiche":
        return (
          <>
            <FormBoxField title="Tipologia" value={data.tipologia || "Non compilato"} />
            <FormBoxField title="Riduzione per attività stagionali o ad uso non continuativo" value={data.riduzione_attivita_stagionali || "Non compilato"} />
            <FormBoxField title="Riduzione per livelli inferiori di prestazione del servizio" value={data.riduzione_livelli_inferiori || "Non compilato"} />
            <FormBoxField title="Riduzione per produzione di rifiuti non conferibili al pubblico servizio" value={data.riduzione_produzione_rifiuti || "Non compilato"} />
            <FormBoxField title="Superficie di formazione dei rifiuti o sostanze" value={data.superficie_rifiuti || "Non compilato"} />
            <FormBoxField title="Uso e tipologie di rifiuti prodotti" value={data.uso_tipologie_rifiuti || "Non compilato"} />
          
          </>
        )
      case "Tassa sui Rifiuti (TARI) - utenze domestiche":
        return (
          <>
            <FormBoxField title="Tipologia" value={data.tipologia || "Non compilato"} />
            <FormBoxField title="Riduzione" value={data.riduzione || "Non compilato"} />

          </>
        )
      default:
        return null;
    }
  }

  const renderFamilyInfo = () => {
    // console.log("renderFamilyInfo", data?.nucleo_familiare)
    return data?.nucleo_familiare?.map((member, index) => {
      return(
        <SummaryCard
          type="nucleo_familiare_dat"
          title={`Componente nucleo familiare: ${member?.anagrafica?.nome} ${member?.anagrafica?.cognome}`}
          cardTitle={`${member?.anagrafica?.nome} ${member?.anagrafica?.cognome}`}
          mod={true}
          update={(data) => update({...data, index})}
          id={"nucleo_familiare"}
          taxID={""}
          service={"DAT-family"}
          registryData={member}
        >
          <FormBoxField title="Nome" value={member?.anagrafica?.nome} />
          <FormBoxField title="Cognome" value={member?.anagrafica?.cognome} />
          <FormBoxField title="Data di nascita" value={member?.anagrafica?.data_di_nascita} />
          <FormBoxField title="Anni" value={member?.anagrafica?.anni} />
          <FormBoxField title="Grado parentela" value={member?.anagrafica?.grado_parentela} />
          <FormBoxField title="Motivo" value={member?.anagrafica?.motivo} />
        </SummaryCard>
      )
    })
  }

  const applicant = data?.richiedente?.[0] || {}
  const credit = data?.credito || {}

  const renderProperty = (property, index) => {
  // const immobile = data?.immobili;

    return (
      <SummaryCard
        key={uniqueIdBuilder()}
        mod={true}
        cardTitle={JSON.parse(localStorage.getItem("configuration"))?.data?.current?.description}
        type="Immobili"
        title={`Immobile ${index + 1}`}
        service="DAT"
        registryData={property}
        update={update}>
        <FormBoxField title="Categoria tipologia immobile" value={property?.dati_immobile?.tipologia_immobile} />
        {
          property?.dati_immobile?.catastale !== "" &&
          <FormBoxField title="Catastale" value={property?.dati_immobile?.catastale} />
        }
        <FormBoxField title="Altri proprietari residenti" value={property?.dati_immobile?.numero_conviventi} />
        <FormBoxField title="Rendita catastale" value={property?.dati_immobile?.rendita_catastale} />
        <FormBoxField title="Percentuale possesso" value={property?.dati_immobile?.percentuale_di_possesso} />
        <FormBoxField title="Immobile variato" value={property?.dati_immobile?.immobile_variato} />
        <FormBoxField title="Mese inizio possesso" value={property?.dati_immobile?.mese_inizio} />
        <FormBoxField title="Mese fine possesso" value={property?.dati_immobile?.mese_fine} />
      </SummaryCard>
    )
  }

  return (
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">

            <SummaryCard
              type="richiedente"
              cardTitle={`${applicant.anagrafica?.nome} ${applicant.anagrafica?.cognome}`}
              mod={true}
              update={update}
              id={"richiedente"}
              taxID={applicant?.anagrafica.codice_fiscale}
              service={props.info.code}
              registryData={applicant}
            >
              <FormBoxField title="Nome" value={applicant?.anagrafica.nome} />
              <FormBoxField title="Cognome" value={applicant?.anagrafica.cognome} />
              <FormBoxField title="Codice Fiscale" value={applicant?.anagrafica.codice_fiscale} />
              <FormBoxField title="Data di nascita" value={applicant?.anagrafica.data_di_nascita} />
              <FormBoxField title="Luogo di nascita" value={applicant?.anagrafica.luogo_di_nascita} />
              <FormBoxField title="Indirizzo residenza" value={applicant?.indirizzi.residenza} />
              <FormBoxField title="Comune residenza" value={applicant?.indirizzi.domicilio} />
              <FormBoxField title="Telefono" value={applicant?.contatti.telefono} />
              <FormBoxField title="Email" value={applicant?.contatti.email} />
              <FormBoxField title="Carta di identità " value={applicant?.documenti.carta_di_identita} />
            </SummaryCard>

            {data?.immobili?.map((property, index) => renderProperty(property, index))}

            {data?.tipologia === values?.types?.[2]?.value && renderFamilyInfo()}

            <SummaryCard
              type="Preferenze"
              mod={true}
              cardTitle={`Preferenze di servizio`}
              service="DAT"
              registryData={{
                preferenze: {
                  taxBreaks: getPrefs()
                }
              }}
              choiceData={getChoices()}
              update={updatePrefs}>
              {renderPrefs()}
            </SummaryCard>

            <Loader loading={loading} fixedPosition/>
            
            <NextBtnForm
              last={true}
              send={onNext}
              back={props.back}
              noSave={false}
              saveRequest={save}
              disabled={!isValidStep("summary")} />


            {
              props.saved &&
              <SuccessfullPopup />
            }

            {
              props.fail &&
              <SuccessfullPopup isError={true} />
            }
          </div>
        </div>
      </div>
    </div>
  )
}


export default Summary;