import React, { useState } from "react";
import { useSelector } from "react-redux";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import { useDispatch } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import Loader from "../../../UI-component/Loader";
import { formatCheckboxValue } from "../../../util";
import { useService } from "../../../classes/useService";
import { uniqueIdBuilder } from "../../../util/Dynamics";
import { SchoolBenefitsService } from "../../../classes/schoolBenefits/SchoolBenefitsService";

function Summary(props) {
  const {data, loading, error, values, save, savePrefs, getPrefs, update, updatePrefs, updateField, isValidStep} = useService(SchoolBenefitsService, props.save, null, props.send);
  
  const applicant = data?.richiedente?.[0] || {}
  const beneficiary = data?.beneficiario?.length > 0 ? data?.beneficiario?.[0] : null;

  // console.log("data?.beneficiario", data?.beneficiario)
  const credit = data?.credito || {}

  const onNext = async () => {
    save(true);
  }

  const getChoices = () => {
    const value = data?.tipologia;
    switch(value){
      case values?.types?.[0]?.value:
        return [
          {
            "options": values.types || [],
            "title": [
              "DAT",
              "TIPOLOGIA"
            ],
            "type": "Select",
            "id": "tipologia"
          },
        ]
      case values?.types?.[1]?.value:
        return []
      case values?.types?.[2]?.value:
        return []
      default:
        return null;
    }
  }

  const renderPrefs = () => {
    const value = data?.tipologia;
    switch(value){
      case values?.types?.[0]?.value:
        return (
          <>
            <FormBoxField title="Tipologia" value={data.tipologia || "Non compilato"} />
            <FormBoxField title="Categoria" value={data.categoria || "Non compilato"} />
            <FormBoxField title="Dichiaro" value={formatCheckboxValue(data.dichiaro?.[0]) || "Non compilato"} />
          </>
        )
      case values?.types?.[1]?.value:
        return (
          <>
            <FormBoxField title="Tipologia" value={data.tipologia || "Non compilato"} />
            <FormBoxField title="Categoria" value={data.categoria || "Non compilato"} />
          </>
        )
      case values?.types?.[2]?.value:
        return (
          <>
            <FormBoxField title="Tipologia" value={data.tipologia || "Non compilato"} />
            {/* <FormBoxField title="" value={"Nessuna preferenza"} /> */}

            {/* <FormBoxField title="Riduzione" value={data.riduzione || "Non compilato"} /> */}

          </>
        )
      default:
        return null;
    }
  }

  const renderDocuments = () => {
    const value = data?.tipologia;
    switch(value){
      case values?.types?.[0]?.value:
        return (
          <>
            <FormBoxField title="Spese sostenute" value={data?.documenti?.spese_sostenute} />
            <FormBoxField title="Certificato iscrizione*" value={data?.documenti?.certificato_iscrizione_frequenza} />
          </>
        )
      case values?.types?.[1]?.value:
        return (
          <>
            <FormBoxField title="Contratto locazione" value={data?.documenti?.contratto_locazione} />
            <FormBoxField title="Pagamento canone" value={data?.documenti?.pagamento_canone} />
            <FormBoxField title="Certificato iscrizione" value={data?.documenti?.certificato_iscrizione_frequenza} />
            
          </>
        )
      case values?.types?.[2]?.value:
        return (
          <>
            <FormBoxField title="Autocertificazione frequenza" value={data?.documenti?.autocertificazione_frequenza} />
            <FormBoxField title="Attestazione iscrizione corso di laurea" value={data?.documenti?.iscrizione_corso_laurea} />
            <FormBoxField title="Codice fiscale" value={data?.documenti?.codice_fiscale} />
            
          </>
        )
      default:
        return null;
    }

  }

  const renderCreditInfo = () => {
    return(
      <SummaryCard
        type="Dati per l'accredito"
        cardTitle={`${credit?.intestatario}`}
        mod={true}
        update={update}
        id={"credito"}
        taxID={""}
        service={props.info.code}
        registryData={credit}
      >
        <FormBoxField title="Intestatario" value={credit?.intestatario} />
        <FormBoxField title="Banca" value={credit?.banca} />
        <FormBoxField title="Filiale" value={credit?.filiale} />
        <FormBoxField title="Indirizzo" value={credit?.indirizzo} />
        <FormBoxField title="Codice IBAN" value={credit?.codice_iban} />
        <FormBoxField title="Documento IBAN" value={credit?.iban} />
      </SummaryCard>
    )
  }

  const renderContentByType = () => {
    const value = data?.tipologia;
    switch(value){
      case values?.types?.[0]?.value:
        return (
          <>
            
          </>
        )
      case values?.types?.[1]?.value:
        return (
          <>
            
          </>
        )
      case values?.types?.[2]?.value:
        return (
          <>
            {data.dichiaro_1?.length > 0 && <SummaryCard
              type="Dichiaro"
              mod={false}
              description="STUDENTI AL TERZO ANNO DELLE SCUOLE SECONDARIE DI PRIMO GRADO"
              cardTitle={``}
              service="DAS"
              >
                {data.dichiaro_1.map(el => {
                    return <FormBoxField title="" value={formatCheckboxValue(el)} key={el} />
                })}
            </SummaryCard>}

            {data.dichiaro_2?.length > 0 && <SummaryCard
              type="Dichiaro"
              mod={false}
              description="STUDENTI DELLE SCUOLE SECONDARIE DI SECONDO GRADO"
              cardTitle={``}
              service="DAS"
              >
                {data.dichiaro_2.map(el => {
                    return <FormBoxField title="" value={formatCheckboxValue(el)} key={el} />
                })}
            </SummaryCard>}

            {data.dichiaro_3?.length > 0 && <SummaryCard
              type="Dichiaro"
              mod={false}
              description="STUDENTI UNIVERSITARI"
              cardTitle={``}
              service="DAS"
              >
                {data.dichiaro_3.map(el => {
                    return <FormBoxField title="" value={formatCheckboxValue(el)} key={el} />
                })}
            </SummaryCard>}
          </>
        )
      default:
        return null;
    }

  }

  const renderBeneficiary = () => {
    return (
      <SummaryCard
        type="beneficiario"
        cardTitle={`${beneficiary.anagrafica?.nome} ${beneficiary.anagrafica?.cognome}`}
        mod={true}
        update={update}
        id={"beneficiario"}
        taxID={beneficiary?.anagrafica.codice_fiscale}
        service={props.info.code}
        registryData={beneficiary}
      >
        <FormBoxField title="Nome" value={beneficiary?.anagrafica.nome} />
        <FormBoxField title="Cognome" value={beneficiary?.anagrafica.cognome} />
        <FormBoxField title="Codice Fiscale" value={beneficiary?.anagrafica.codice_fiscale} />
        <FormBoxField title="Data di nascita" value={beneficiary?.anagrafica.data_di_nascita} />
        <FormBoxField title="Luogo di nascita" value={beneficiary?.anagrafica.luogo_di_nascita} />
        <FormBoxField title="Indirizzo residenza" value={beneficiary?.indirizzi.residenza} />
        <FormBoxField title="Comune residenza" value={beneficiary?.indirizzi.domicilio} />
        <FormBoxField title="Telefono" value={beneficiary?.contatti.telefono} />
        <FormBoxField title="Email" value={beneficiary?.contatti.email} />
        <FormBoxField title="Carta di identità " value={beneficiary?.documenti.carta_di_identita} />
      </SummaryCard>
    )
  }

  return (
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">

            <SummaryCard
              type="richiedente"
              cardTitle={`${applicant.anagrafica?.nome} ${applicant.anagrafica?.cognome}`}
              mod={true}
              update={update}
              id={"richiedente"}
              taxID={applicant?.anagrafica.codice_fiscale}
              service={props.info.code}
              registryData={applicant}
            >
              <FormBoxField title="Nome" value={applicant?.anagrafica.nome} />
              <FormBoxField title="Cognome" value={applicant?.anagrafica.cognome} />
              <FormBoxField title="Codice Fiscale" value={applicant?.anagrafica.codice_fiscale} />
              <FormBoxField title="Data di nascita" value={applicant?.anagrafica.data_di_nascita} />
              <FormBoxField title="Luogo di nascita" value={applicant?.anagrafica.luogo_di_nascita} />
              <FormBoxField title="Indirizzo residenza" value={applicant?.indirizzi.residenza} />
              <FormBoxField title="Comune residenza" value={applicant?.indirizzi.domicilio} />
              <FormBoxField title="Telefono" value={applicant?.contatti.telefono} />
              <FormBoxField title="Email" value={applicant?.contatti.email} />
              <FormBoxField title="Carta di identità " value={applicant?.documenti.carta_di_identita} />
            </SummaryCard>

            {beneficiary && renderBeneficiary()}
            <SummaryCard type="Documenti">
              {renderDocuments()}
            </SummaryCard>

            {renderCreditInfo()}

            {renderContentByType()}

            <SummaryCard
              type="Preferenze"
              mod={false}
              cardTitle={`Preferenze di servizio`}
              service="DAT"
              registryData={{
                preferenze: {
                  schoolBenefits: getPrefs()
                }
              }}
              choiceData={getChoices()}
              update={updatePrefs}>
              {renderPrefs()}
            </SummaryCard>

            <Loader loading={loading} fixedPosition/>
            
            <NextBtnForm
              last={true}
              send={onNext}
              back={props.back}
              noSave={false}
              saveRequest={save}
              disabled={!isValidStep("summary")} />


            {
              props.saved &&
              <SuccessfullPopup />
            }

            {
              props.fail &&
              <SuccessfullPopup isError={true} />
            }
          </div>
        </div>
      </div>
    </div>
  )
}


export default Summary;