import { Service } from "../Service";

const VALUES_BY_TENANT = {
    "default": {
        types: [
            {id: 1, value: "Rimborso parziale o totale delle spese di viaggio sostenute dagli studenti fuori sede"},
            {id: 2, value: "Integrazione per il pagamento del canone di locazione sostenuto dagli studenti fuori sede"},
            {id: 3, value: "Contributo di merito destinato agli studenti della scuola secondaria di primo e secondo grado, e agli studenti universitari in possesso della laurea di primo o secondo livello"},
        ],
        categories: [
            {id: 1, value: "categoria 1: studenti iscritti ad una scuola secondaria di secondo grado"},
            {id: 2, value: "categoria 2: studenti iscritti ad un corso di laurea triennale, di laurea magistrale o di laurea magistrale a ciclo unico"},
        ],
        declarations: [
            { value: "1-studente frequentante la scuola secondaria di secondo grado situata in territorio regionale"},
            { value: "2-studente universitario frequentante un corso di studi all’interno del territorio regionale "},
            { value: "3-studente frequentante scuola secondaria di secondo grado situata fuori dal territorio regionale (fuori sede)"},
            { value: "4-studente frequentante corso universitario situato fuori dal territorio regionale (fuori sede)"},
        ],
        declarations_1: [
            { value: "1-Di avere conseguito nell’esame finale un voto non inferiore a 10/10"},
        ],
        declarations_2: [
            { value: "1-Di aver regolarmente frequentato la classe prima riportando per l’anno oggetto di bando una media di voti non inferiore a 8/10, calcolata su tutte le materie curricolari"},
            { value: "2-Di aver regolarmente frequentato la classe seconda riportando per l’anno oggetto di bando una media di voti non inferiore a 8/10, calcolata su tutte le materie curricolari"},
            { value: "3-Di aver regolarmente frequentato la classe terza riportando per l’anno oggetto di bando una media di voti non inferiore a 8/10, calcolata su tutte le materie curricolari"},
            { value: "4-Di aver regolarmente frequentato la classe quarta riportando per l’anno oggetto di bando una media di voti non inferiore a 8/10, calcolata su tutte le materie curricolari"},
            { value: "5-Di non avere frequentato da ripetente l’anno scolastico oggetto di bando"},
            { value: "6-Di avere riportato un voto in condotta superiore a 7/10"},
            { value: "7-Di avere conseguito nel diploma finale un voto non inferiore a 90/100"},
            { value: "8-Di essere residente nel Comune di Neoneli alla data della domanda e per l’intero anno scolastico oggetto di bando"},
        ],
        declarations_3: [
            { value: "1-Di essere iscritto/a da un numero di anni inferiore o uguale alla durata legale del corso di riferimento, cioè di non essere fuori corso"},
            { value: "2-Di avere conseguito un diploma di laurea di primo livello"},
            { value: "3-Di avere conseguito un diploma di laurea di secondo livello"},
            { value: "4-Di avere conseguito un diploma di laurea a ciclo unico presso una Università dell’Italia o di uno Stato dell’Unione Europea, legalmente riconosciuto dallo Stato italiano, con un punteggio minimo di 100/110"},
        ],
    }
}


const serviceId = "SCHOOL_BENEFITS" 
const serviceInternalId = "schoolBenefits"

export class SchoolBenefitsService extends Service {
    static SERVICE_ID = serviceId
    static SERVICE_INTERNAL_ID = serviceInternalId
    //////////////////////////////////
    // Contructor
    //////////////////////////////////
    constructor(ctxService) {
        // console.log("ctx service", ctxService)
        const data = {
            ...(ctxService || {}),
        }
        super(VALUES_BY_TENANT, data, ctxService, serviceId, serviceInternalId);
    }

    //////////////////////////////////
    // Generic Methods
    //////////////////////////////////

    getFields() {
        const value = this.data?.tipologia;
        const defaultFieds = ["richiedente", "tipologia", "beneficiario", "credito", "documenti"]
        switch (value) {
            case VALUES_BY_TENANT.default.types[0]?.value:
                return [...defaultFieds, "categoria", "dichiaro"]
            case VALUES_BY_TENANT.default.types[1]?.value:
                return [...defaultFieds, "categoria"]
            case VALUES_BY_TENANT.default.types[2]?.value:
                return [...defaultFieds, "dichiaro_1", "dichiaro_2", "dichiaro_3"]
            default:
                return defaultFieds
        }
    }

    getDefaultPrefs(){
        const value = this.data?.tipologia;
        switch (value) {
            case VALUES_BY_TENANT.default.types[0]?.value:
                return {
                    tipologia: this.data?.tipologia || "",
                    categoria: this.data?.categoria || "",
                    dichiaro: this.data?.dichiaro || "",
                    // documenti: this.data?.documenti || "",
                }
            case VALUES_BY_TENANT.default.types[1]?.value:
                return {
                    tipologia: this.data?.tipologia || "",
                    categoria: this.data?.categoria || "",
                    // documenti: this.data?.documenti || "",

                }
            case VALUES_BY_TENANT.default.types[2]?.value:
                return {
                    tipologia: this.data?.tipologia || "",
                    // documenti: this.data?.documenti || "",
                    dichiaro_1: this.data?.dichiaro_1 || "",
                    dichiaro_2: this.data?.dichiaro_2 || "",
                    dichiaro_3: this.data?.dichiaro_3 || "",
                    
                }
            default:
                return {
                    tipologia: this.data?.tipologia || "",
                }
        }
    }

    getPrefs(){
        const value = "";
        switch(value){
            default:
                return super.getPrefs()
        }   
    }
    //////////////////////////////////
    // Field validation
    //////////////////////////////////

    isValidField(id) {
        switch (id) {
            case "tipologia":
                return this.data?.[id] ? true : false
            case "beneficiario":
                return this.data?.[id]?.length >=0 ? true : false
            case "credito":
                // const credit = data?.[id] || {}
                const credit = {
                    intestatario: "",
                    banca: "",
                    filiale: "",
                    indirizzo: "",
                    codice_iban: "",
                    iban: "",
                    base64_iban: "",
                    ...this.data?.[id]
                }
                let errByKey = {}
                // console.log("Object.keys(credit)", Object.keys(credit))
                for (let i of Object.keys(credit)) {
                    let err = false;
                    if (credit[i] === "" || !credit[i]) {
                        err = true;
                    }
                    else if (i === "codice_iban") {
                        const regex = RegExp("^IT\\d{2}[A-Za-z]\\d{10}[0-9A-Za-z]{12}$");
                        err = !regex.test(credit[i]);
                    }
                    if(err) errByKey[i] = err;
                }
                return Object.keys(errByKey)?.length > 0 ? errByKey : true;
            case "dichiaro":
                return this.data?.[id]?.length > 0 ? true : false
            case "dichiaro_1":
            case "dichiaro_2":
            case "dichiaro_3":
                return (this.data?.dichiaro_1?.length > 0
                    || this.data?.dichiaro_2?.length > 0
                    || this.data?.dichiaro_3?.length > 0) ? true : false
            case "documenti":
                const value = this.data?.tipologia;
                switch(value){
                    case VALUES_BY_TENANT.default.types[0]?.value:
                        return (this.data?.documenti?.certificato_iscrizione_frequenza) ? true : false
                    case VALUES_BY_TENANT.default.types[1]?.value:
                        return (
                            this.data?.documenti?.contratto_locazione
                            && this.data?.documenti?.pagamento_canone
                            && this.data?.documenti?.certificato_iscrizione_frequenza
                        ) ? true : false
                    case VALUES_BY_TENANT.default.types[2]?.value:
                        return (
                            (this.data?.documenti?.autocertificazione_frequenza || this.data?.documenti?.iscrizione_corso_laurea)
                            && this.data?.documenti?.codice_fiscale
                        ) ? true : false

                }
            default:
                return super.isValidField(id);
        }
    }

    isValidData(isSendRequest){
        const value = "";
        let requiredFields = [];
        // console.log("is valid data", isSendRequest)
        switch(value){
            default:
                if(isSendRequest) requiredFields = this.getFields()
                else requiredFields = []
        }

        // console.log("is valid data",requiredFields)

        if(requiredFields?.length > 0) return this.checkFields(requiredFields)        

        return true;
    }

    //////////////////////////////////
    // Steps validation
    //////////////////////////////////
    isValidStep(stepId){
        this.checkErrors();
        const value = this.data?.tipologia;
        // console.log("is valid step", stepId, value, this.error)
        switch(stepId){
            case "general":
                return (this.error?.tipologia) ? false : true
            case "specific":
            case "summary":
                switch(value){
                    case VALUES_BY_TENANT.default.types[0]?.value:
                        return (
                            this.error?.richiedente 
                            || (typeof this.error.credito === "object" ? Object.keys(this.error.credito)?.length > 0 : this.error.credito)
                            || this.error?.categoria
                            || this.error?.dichiaro
                            || this.error?.documenti
                        ) ? false : true
                    case VALUES_BY_TENANT.default.types[1]?.value:
                        return (
                            this.error?.richiedente 
                            || this.error?.documenti
                            || (typeof this.error.credito === "object" ? Object.keys(this.error.credito)?.length > 0 : this.error.credito)
                        ) ? false : true
                    case VALUES_BY_TENANT.default.types[2]?.value:
                        return (
                            this.error?.richiedente 
                            || this.error?.documenti
                            || (this.error?.dichiaro_1 && this.error?.dichiaro_2 && this.error?.dichiaro_3)
                            || (typeof this.error.credito === "object" ? Object.keys(this.error.credito)?.length > 0 : this.error.credito)
                        ) ? false : true
                }
                
            default:
                return false;
        }
    }
    
}