import React, { useEffect, useState } from "react";
import { ServiceRequestAPI } from "../Apis/ServiceRequestAPI";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { addDaysToDate, getServiceExpireDate } from "./service/utils";
import { getTenantCode } from "../util";

function CompletitionBox(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [expireDate, setExpireDate] = useState(null);
  const [successfull, setSuccessfull] = useState({
    success: false,
    inProcess: false,
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const externalSiteCheck = () => {
    if (props?.doc?.link?.includes("levanto")) {
      return `${
        `https://comune.levanto.sp.it/` +
        props.doc.link.substring(27, props.doc.link.length)
      }`;
    } else if (props?.doc?.link?.includes("riccodelgolfo")) {
      return `${
        `https://www.comune.riccodelgolfo.sp.it/` +
        props.doc.link.substring(33, props.doc.link.length)
      }`;
    } else return props?.doc?.link;
  };

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const externalUrl = () => {
    // console.log("externalUrl", props.doc);
    window.open(
      `https://www.telemoney.cloud/istanze/dettaglioDomanda.xhtml?id=${props.doc.id}&token=${props.doc.token_user}`,
      "_self",
      "noopener,noreferrer"
    );
  };

  const svgFilter = () => {
    let img = "";
    // console.log(props.doc.state);
    switch (props.doc.state) {
      case "to_complete":
        img = "folder-incomplete";
        break;
      case "completed":
        img = "folder-concluded";
        break;
      case "waiting":
        img = "folder-wating";
        break;
      case "in_progress":
        img = "icone-stati-in-corso";
        break;
      case "draft":
        img = "icone-stati-in-bozza";
        break;
      case "error":
        img = "icone-stati-non-conclusa";
        break;
      case "accepted":
        img = "folder-concluded";
        break;
      case "PRESENTATA":
        img = "folder-wating";
        break;
      case "APPROVATA":
        img = "folder-concluded";
        break;
      case "CANCELLATA":
        img = "folder-incomplete";
        break;
      case "ANNULLATA":
        img = "folder-incomplete";
        break;
      case "RESPINTA":
        img = "icone-stati-non-conclusa";
        break;

      default:
        break;
    }

    return `../assets/images/${img}.svg`;
  };

  const savedApi = async (e) => {
    try {
      const res = await ServiceRequestAPI.getSaved(props.doc.practiceNumber);
      // console.log("res", res);
      navigate(`/${props.doc.serviceLink}`, { state: res.data });
    } catch (err) {}

    //send the data to the service u need with useNavigate useLocation
    //and then saved it once u are in that page
  };

  const onFileLoad = async (e) => {
    const practiceNumber = props.doc?.practiceNumber;

    const formData = new FormData();

    formData.append("practice_number", practiceNumber);
    formData.append("file_ricevuta", e.target.files[0]);

    try {
      setLoading(true);
      const res = await ServiceRequestAPI.fileUpload(formData);

      setLoading(false);
      setSuccessfull({ success: true, inProcess: true });

      timing(2000);
    } catch (err) {
      setLoading(false);
      setSuccessfull({ success: false, inProcess: false });
      timing(2000);

      console.log(err);
    }
  };

  const timing = (time) => {
    setTimeout(() => {
      setSuccessfull((prev) => {
        return { ...prev, inProcess: false };
      });
    }, time);
  };

  const checkServiceExpireDate = async () => {
    const { practiceNumber, date, services } = props.doc;
    const practiceNumberArr = practiceNumber?.split("-");

    if (practiceNumberArr?.length > 1) {
      const serviceId = practiceNumberArr[0];

      const parseDate = (dateString) => {
        const [day, month, year] = dateString.split("/").map(Number);
        return new Date(Date.UTC(year, month - 1, day));
      };

      const formatDate = (date) => {
        return date
          .toLocaleString("it-IT", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/\//g, "/");
      };

      const addDaysToDate = (dateString, days) => {
        const date = parseDate(dateString);
        date.setUTCDate(date.getUTCDate() + parseInt(days, 10));
        return formatDate(date);
      };

      if (services?.date?.[serviceId] && services?.days?.[serviceId]) {
        const serviceDate = parseDate(services.date[serviceId]);
        const now = new Date();

        if (serviceDate > now) {
          setExpireDate(services.date[serviceId]);
        } else {
          setExpireDate(addDaysToDate(date, services.days[serviceId]));
        }
      } else if (services?.date?.[serviceId]) {
        setExpireDate(services.date[serviceId]);
      } else if (services?.days?.[serviceId]) {
        setExpireDate(addDaysToDate(date, services.days[serviceId]));
      }
    }
  };

  const openLink = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    if (!expireDate) checkServiceExpireDate();
  }, [props?.doc?.services]);

  // Funzione per convertire 'DD/MM/YYYY' in un oggetto Date
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("/").map(Number);
    // I mesi in JavaScript sono indicizzati da 0 (Gennaio è 0, Dicembre è 11)
    return new Date(year, month - 1, day);
  };

  const expDateFunc = () => {
    const today = parseDate(props.doc.date);
    const expire = new Date(parseDate(expireDate));
    const diffTime = Math.abs(expire - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays === 1
      ? "Data elaborazione della richiesta: entro un giorno lavorativo"
      : `Data elaborazione della richiesta: entro il ${expireDate}`;
  };

  return (
    <div className="accordion-item">
      <div className="accordion-header">
        <button
          className="accordion-button collapsed title-snall-semi-bold"
          type="button"
          onClick={toggleOpen}
        >
          <div className="button-wrapper">
            {props.doc.name}
            <div className="icon-wrapper">
              <img
                className="icon-folder icon-size"
                src={svgFilter()}
                alt="Non trovata"
              />
              <span className="text-capitalize">
                {props.doc.italian_state?.toLowerCase()}
              </span>
            </div>
          </div>
        </button>
        <p className="accordion-date title-xsmall-regular mb-0">
          {props?.doc?.state === "draft" &&
            `Data salvataggio della richiesta:  ${props.doc.date}`}
          {props?.doc?.state !== "draft" &&
            `Data presentazione della richiesta:  ${props.doc.date}`}
        </p>
      </div>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <Loader loading={loading} />
      </div>
      {isOpen && (
        <div>
          <div className="accordion-body">
            {props?.doc?.protocolId && props?.doc?.protocolDate && (
              <p className="mb-2 fw-normal">
                {`Numero e data di protocollo: ${props?.doc?.protocolId} del ${props?.doc?.protocolDate}`}
              </p>
            )}
            {expireDate &&
              (props?.doc?.state === "waiting" ||
                props?.doc?.state === "in_progress" ||
                props?.doc?.state === "to_complete") && (
                <p className="mb-2 fw-normal">{`${expDateFunc()}`}</p>
              )}

            <p className="mb-2 fw-normal">
              Pratica:
              <span className="label">
                {" "}
                {props.doc.practiceNumber || " Non presente"}
              </span>
            </p>

            <a
              style={{ marginRight: "10px" }}
              href={externalSiteCheck()}
              className="mb-2"
            >
              <span className="t-primary">Scheda servizio</span>
            </a>
            <div className="cmp-icon-list">
              {successfull.inProcess ? (
                !successfull.success ? (
                  <p style={{ color: "red", fontWeight: "550" }}>
                    C'e' stato un problema con la richiesta
                  </p>
                ) : (
                  <p style={{ color: "green", fontWeight: "550" }}>
                    File caricato correttamente
                  </p>
                )
              ) : (
                <></>
              )}
              <div className="link-list-wrapper">
                <ul className="link-list">
                  {props.doc.state !== "draft" && props.doc.receipt && (
                    <div style={{ width: "442px", marginLeft: "-22px" }}>
                      {props.doc.amount === "0" ? (
                        <p style={{ color: "red", fontWeight: "500" }}>
                          Il documento F24 per la pratica n.{" "}
                          {props.doc.practiceNumber} - Pagamento tributi IMU -
                          non è stato generato perché l’importo totale è 0,00
                          euro.
                        </p>
                      ) : (
                        <li className="shadow p-0">
                          <button
                            onClick={() => openLink(props.doc?.receipt)}
                            style={{
                              padding: "8px",
                              background: "white",
                              color: "var(--primary)",
                              boxShadow: "0 .3px 2px grey",
                              marginTop: ".5rem",
                            }}
                            type="button"
                            className="btn btn-icon btn-re square"
                            data-focus-mouse="false"
                          >
                            {props.doc.name.toLowerCase().includes("imu")
                              ? "Scarica F-24"
                              : "Scarica ricevuta"}
                          </button>
                        </li>
                      )}
                      {/* {
                                                (props.doc?.practiceNumber?.startsWith("PSP") ||
                                                    props.doc?.practiceNumber?.startsWith("PPC") ||
                                                    ((props.doc?.practiceNumber?.startsWith("PPI")) && props.doc?.temp)) &&
                                                props.doc.state === "to_complete" &&
                                                <div
                                                    style={{ marginTop: "1rem" }}>

                                                    <div>
                                                        <label
                                                            style={{ background: "var(--primary)" }}
                                                            className="btn btn-icon btn-re square">
                                                            <input
                                                                style={{ display: "none" }}
                                                                type="file"
                                                                onChange={onFileLoad} />
                                                            Carica ricevuta con marca da bollo
                                                        </label>
                                                    </div>
                                                </div>
                                            } */}
                    </div>
                  )}
                </ul>
              </div>

              {props.doc.state === "draft" && (
                <button
                  onClick={savedApi}
                  style={{ padding: "8px", background: "var(--primary)" }}
                  type="button"
                  className="btn btn-icon btn-re square"
                  data-focus-mouse="false"
                >
                  Perfeziona pratica
                </button>
              )}

              {getTenantCode() === "H860" &&
              props.doc.serviceLink === "telemoney" ? (
                <button
                  onClick={externalUrl}
                  style={{ padding: "8px", background: "var(--primary)" }}
                  type="button"
                  className="btn btn-icon btn-re square"
                  data-focus-mouse="false"
                >
                  Dettagli pratica
                </button>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CompletitionBox;
