import { createSlice } from "@reduxjs/toolkit";

const initState = {
    privacy: false,
    data: {
        richiedente: [],
        richiedente_2: [],
        type_richiedente: [],
        beneficiario: [],
        disabile: [],
        savedBen: [],
        savedDis: [],
        test_contribuente: [],
        test_type: [],
        "autore_della_segnalazione": [],
        disservizio: {},
        isee: [],
        cimitero: {
            concessionario: [],
            defunto: [],
        },
        bonus: {
            richiedente: [],
            credito: {
            }
        },
        access: {
            richiedente: []
        },
        preferenze: {
            mensa_scolastica: {
                "scuola": "",
                "classe": "",
                tariffa_base: "",
                tariffa_riduzione: "",
                dieta_diversificata: "",
                disabilita: "",
                dichiaro: [],
            },
            trasporto_scolastico: {
                "scuola": "",
                "classe": "",
                "fermata_abituale": {
                    andata: "",
                    ritorno: ""
                },
                "delegati": {
                    principale: "",
                    secondario: "",
                    terziario: ""
                },
                "fermata_alternativa": {
                    andata: "",
                    ritorno: ""
                },
                tariffa_base: "",
                disabilità: "",
                consapevole: [],
                dichiaro: [],
            },
            suolo_pubblico: {
                presupposto_del_canone: "",
                tipo_occupazione: {
                    categoria: "",
                    sottocategoria: ""
                },
                caratteristiche_occupazione: {
                    tipologia: ""
                },
                luogo: {
                    indirizzo: ""
                },
                dimensioni: {
                    lunghezza: "",
                    altezza: "",
                    profondita: "",
                    superficie: ""
                },
                periodo: {
                    giorno_e_ora_di_inizio: "",
                    giorno_e_ora_di_fine: ""
                },
                motivo: {
                    descrizione: ""
                },
                dichiaro: [],
                marca_da_bollo: {
                    numero: "",
                    data: ""
                }
            },
            cimitero: {
                arrivo: {
                    id: "",
                    value: ""
                },
                tipo_di_sepoltura: {
                    id: "",
                    value: ""
                },
                cimitero: {
                    id: "",
                    value: ""
                },
                casellario: {
                    id: "",
                    value: ""
                },
                loculo: {
                    id: "",
                    value: ""
                },
                durata: {
                    id: "",
                    value: ""
                }
            },
            accessi: {
                tipologia: "",
                modalita: "",
                indirizzo_spedizione: "",
                motivo: "",
                dichiaro: [],
                chiede: {
                    informazione: "",
                    documento: "",
                    dato: ""
                }

            },
            parcheggio: {
                tipo_di_invalidita: [],
                chiede: [],
                vecchio_contrassegno: "",
                marca_da_bollo: {
                    numero: "",
                    data: ""
                }
            },
            passo_carrabile: {
                motivo: "",
                chiede: "",
                tipologia: "",
                provvedimento: {
                    numero: "",
                    data: ""
                },
                luogo: {
                    indirizzo: ""
                },
                dimensioni: {
                    lunghezza: "",
                    profondita: "",
                    superficie: ""
                },
                immobile: {
                    foglio: "",
                    particella: "",
                    subalterno: "",
                    categoria: ""
                }
            },
            bonus: {
                tipologia: ""
            },
            matrimonio: {
                tipo_matrimonio: "",
                m_data: "",
                numero_1: "",
                data_marca_1: "",
                numero_2: "",
                data_marca_2: "",
            },
        },
        richiesta: {
            applicant: {

            },
            request: {

            }
        },
        ufficio: {
            richiedente: {}
        },
        imu: {
            contribuente: [],
            immobili: [],
            coobbligato: {}
        },
        fattura: {

        },
        pagamento: {
            fattura: "",
            metodo: "",
            importo: "",
            data_emissione: "",
            iuv: "",
            link: ""
        },
        avviso: "",
        error: false,
        stopped: false
    }
}

const stepSlice = createSlice({
    name: "stepSlice",
    initialState: initState,
    reducers: {
        togglePvt,
        dynamicSave,
        dynamicEdit,
        changeSelected,
        switchError,
        clear,
        onDraftData,
        onImmRemove,
        baseRemove,
        alive
    }
})

//updaed the privacy (given session)
function togglePvt(state, action) {
    state.privacy = action.payload;
}

function dynamicSave(state, action) {
    switch (action.payload.id) {
        case "Beneficiario":
            state.data.beneficiario = Array.isArray(action.payload.data) ? action.payload.data : [action.payload.data];
            break;
        case "SavedBen":
            state.data.savedBen = [];
            for (let i of action.payload.data) {
                state.data.savedBen.push(i);
            }
            break;
        case "SavedDis":
            state.data.savedDis = [];
            for (let i of action.payload.data) {
                state.data.savedDis.push(i);
            }
            break;
        case "Richiedente":
            state.data.richiedente = action.payload.data ? [action.payload.data] : [];
            break;
        case "Richiedente_2":
            state.data.richiedente_2 = [action.payload.data];
            break;
        case "type_richiedente":
            if (state.data.type_richiedente)
                state.data.type_richiedente = [action.payload.data];
            break;
        case "Disabile":
            state.data.disabile = [action.payload.data];
            break;
        case "ISEE":
            state.data.isee = [action.payload.data];
            break;
        case "Richiesta":
            state.data.richiesta = action.payload.data;
            break;
        case "disservizio":
            state.data.disservizio = action.payload.data;
            break;
        case "Ufficio":
            if (action.payload.external === "richiedente") {
                state.data.ufficio.richiedente = action.payload.data;
                return;
            }

            if (action.payload.external) {
                state.data.ufficio[action.payload.external][action.payload.internalId] = action.payload.data;
                return;
            }

            state.data.ufficio[action.payload.internalId] = action.payload.data;

            break;
        case "Autore della segnalazione":
            state.data.autore_della_segnalazione = action.payload.data;
            break;
        case "Contribuente":
            state.data.imu.contribuente = action.payload.data;
            break;
        case "Immobili":
            state.data.imu.immobili = action.payload.data;
            break;
        case "Coobbligato":
            state.data.imu.coobbligato = action.payload.data;
            break;
        case "Cimitero":
            state.data.cimitero = action.payload.data;
            break;
        case "Matrimonio":
            state.data.matrimonio = action.payload.data;
            break;
        case "Preferenze":
            state.data.preferenze[action.payload.internalId] = action.payload.data;
            break;
        case "code":
            state.data.avviso = action.payload.data;
            break;
        case "fattura":
            state.data.fatture = action.payload.data;
            break;
        case "multa":
            state.data.multa = action.payload.data;
            break;
        case "practiceNumber":
            state.data.practiceNumber = action.payload.data;
            break;
        case "pagamento":
            state.data.pagamento = action.payload.data;
            break;
        case "Bonus":
            state.data.bonus = action.payload.data;
            break;
        case "Fee":
            state.data.fee = action.payload.data;
            break;
        case "Marriage":
            state.data.matrimonio = action.payload.data;
            break;
        case "Access":
            state.data.access = action.payload.data;
            break;
        case "TowZone":
            state.data.towZone = action.payload.data;
            break;
        case "TAX_BREAKS":
            state.data.taxBreaks = action.payload.data;
        case "SCHOOL_BENEFITS":
            state.data.schoolBenefits = action.payload.data;
        default:
            break;
    }
}

function dynamicEdit(state, action) {
    switch (action.payload.id) {
        case "Beneficiario":

            functionalEditFilter(state, action, "beneficiario")
            break;
        case "Richiedente":
            functionalEditFilter(state, action, "richiedente");
            break;
        case "type_richiedente":
            functionalEditFilter(state, action, "type_richiedente");
            break;
        case "ISEE":
            state.data.isee[0] = action.payload.data;
            break;
        case "Autore della segnalazione":

            for (let i = 0; i < state.data.autore_della_segnalazione.length; i++) {

                if (state.data.autore_della_segnalazione[i].anagrafica.codice_fiscale === action.payload.data.anagrafica.codice_fiscale) {
                    state.data.autore_della_segnalazione[i] = action.payload.data;
                    break;
                }
            }
            // functionalEditFilter(state,action,"richiedente");
            break;
        case "Disservizio":
            state.data.disservizio = action.payload.data;
            break;
        case "Preferenze":
            const data = action.payload.data;
            state.data.preferenze = data;
            break;
        case "Contribuente":
            for (let i = 0; i < state.data["imu"]["contribuente"].length; i++) {
                if (state.data['imu']["contribuente"][i].anagrafica.codice_fiscale === action.payload.data.anagrafica.codice_fiscale) {
                    state.data['imu']["contribuente"][i] = action.payload.data;
                    break;
                }
            }
            break;
        case "Immobili":
            if (action.payload.add) {
                let i = 0;
                Object.keys(action.payload.data[0]).forEach(key => {
                    state.data.imu.immobili[i][key] = action.payload.data[i][key];
                    i += 1;
                })

                return;
            }

            for (let i = 0; i < state.data.imu.immobili.length; i++) {
                if (state.data.imu.immobili[i].id === action.payload.internalID)
                    state.data.imu.immobili[i] = action.payload.data;
            }
            break;
        case "Coobbligato":
            state.data.imu.coobbligato = action.payload.data;
            break;
        case "Ufficio":
            state.data.ufficio.richiedente = action.payload.data;
            break;
        default:
            break;
    }
}

function functionalEditFilter(state, action, id) {
    for (let i = 0; i < state.data[id].length; i++) {
        if (state.data[id][i].anagrafica.codice_fiscale === action.payload.data.anagrafica.codice_fiscale) {
            state.data[id][i] = action.payload.data;
            break;
        }
    }
}

function onImmRemove(state, action) {
    for (let i = 0; i < state.data.imu.immobili.length; i++) {
        if (state.data.imu.immobili[i].id === action.payload.id) {
            state.data.imu.immobili.splice(i, 1);
            break;
        }
    }

}

function baseRemove(state, action) {
    const service = action.payload.service;
    const id = service !== "PSP" ? action.payload.id : "type_richiedente";
    const data = action.payload.data;

    if (service === 'PTI') {
        const idx = state.data["imu"][id].findIndex((el) => {
            return el.anagrafica.Nome === data.anagrafica.Nome;
        });

        state.data["imu"][id].splice(idx, 1);
        return;
    }

    const idx = state.data[id].findIndex((el) => {
        return el.anagrafica.Nome === data.anagrafica.Nome;
    });

    state.data[id].splice(idx, 1);
}

//updates the selection of the customer (session)
function changeSelected(state, action) {
    filtering(action.payload.id.replaceAll(" ", "_").toLowerCase(), state, action);
}

function switchError(state, action) {
    state.data.error = action.payload.error;
}

//clears the store back to default (init state)
function clear(state) {
    state.privacy = false;

    state.data = {
        richiedente: [],
        richiedente_2: [],
        type_richiedente: [],
        beneficiario: [],
        disabile: [],
        savedBen: [],
        savedDis: [],
        test_contribuente: [],
        test_type: [],
        isee: [],
        cimitero: {
            concessionario: [],
            defunto: [],
        },
        bonus: {
            richiedente: [],
            credito: {},
            isee: []
        },
        fee: {
            richiedente: [],
            code: "",
            fatture: {},
            pagamento: {}
        },
        matrimonio: {
            richiedente: [],
            richiedente_2: [],
            marca_bollo_1: {},
            marca_bollo_2: {},
        },
        preferenze: {
            mensa_scolastica: {
                "scuola": "",
                "classe": "",
                tariffa_base: "",
                tariffa_riduzione: "",
                dieta_diversificata: "",
                disabilita: "",
                dichiaro: [],
            },
            trasporto_scolastico: {
                "scuola": "",
                "classe": "",
                "fermata_abituale": {
                    andata: "",
                    ritorno: ""
                },
                "delegati_al_ritiro": {
                    principale: "",
                    secondario: "",
                    terziario: ""
                },
                "fermata_alternativa": {
                    andata: "",
                    ritorno: ""
                },
                tariffa_base: "",
                disabilità: "",
                consapevole: [],
                dichiaro: [],
            },
            suolo_pubblico: {
                presupposto_del_canone: "",
                tipo_occupazione: {
                    categoria: "",
                    sottocategoria: ""
                },
                luogo: {
                    indirizzo: ""
                },
                dimensioni: {
                    lunghezza: "",
                    altezza: "",
                    profondita: "",
                    superficie: ""
                },
                periodo: {
                    giorno_e_ora_di_inizio: "",
                    giorno_e_ora_di_fine: ""
                },
                motivo: {
                    descrizione: ""
                },
                dichiaro: [],
                marca_da_bollo: {
                    numero: "",
                    data: ""
                }
            },
            cimitero: {
                arrivo: {
                    id: "",
                    value: ""
                },
                tipo_di_sepoltura: {
                    id: "",
                    value: ""
                },
                cimitero: {
                    id: "",
                    value: ""
                },
                casellario: {
                    id: "",
                    value: ""
                },
                loculo: {
                    id: "",
                    value: ""
                },
                durata: {
                    id: "",
                    value: ""
                }
            },
            accessi: {
                tipologia: "",
                modalita: "",
                indirizzo_spedizione: "",
                motivo: "",
                dichiaro: [],
                chiede: {
                    informazione: "",
                    documento: "",
                    dato: ""
                }
            },
            parcheggio: {
                tipo_di_invalidita: [],
                chiede: [],
                vecchio_contrassegno: "",
                marca_da_bollo: {
                    numero: "",
                    data: ""
                }
            },
            passo_carrabile: {
                motivo: "",
                chiede: "",
                tipologia: "",
                provvedimento: {
                    numero: "",
                    data: ""
                },
                luogo: {
                    indirizzo: ""
                },
                dimensioni: {
                    lunghezza: "",
                    profondita: "",
                    superficie: ""
                },
                immobile: {
                    foglio: "",
                    particella: "",
                    subalterno: "",
                    categoria: ""
                }
            },
            bonus: {
                tipologia: ""
            },
            matrimonio: {
                tipo_matrimonio: "",
                m_data: "",
                numero_1: "",
                data_marca_1: "",
                numero_2: "",
                data_marca_2: "",
            },
        },
        richiesta: {
            applicant: {

            },
            request: {

            }
        },
        "autore_della_segnalazione": [],
        disservizio: {},
        ufficio: { richiedente: {} },
        imu: {
            contribuente: [],
            immobili: [],
            coobbligato: {}
        },
        fattura: {

        },
        pagamento: {
            fattura: "",
            metodo: "",
            importo: "",
            data_emissione: "",
            iuv: "",
            link: ""
        },
        avviso: "",
        error: false,
        stopped: false
    }
}

function alive(state) {
    state.data.stopped = false;
}

function onDraftData(state, action) {
    state.privacy = false;

    const data = action.payload.data;
    const saved = action.payload.saved;
    if (!saved) {

        const defaultPref = {
            mensa_scolastica: {
                "scuola": "",
                "classe": "",
                tariffa_base: "",
                tariffa_riduzione: "",
                dieta_diversificata: "",
                disabilita: "",
                dichiaro: [],
            },
            trasporto_scolastico: {
                "scuola": "",
                "classe": "",
                "fermata_abituale": {
                    andata: "",
                    ritorno: ""
                },
                "delegati": {
                    principale: "",
                    secondario: ""
                },
                "fermata_alternativa": {
                    andata: "",
                    ritorno: ""
                },
                tariffa_base: "",
                disabilità: "",
                consapevole: [],
                dichiaro: [],
            },
            suolo_pubblico: {
                presupposto_del_canone: "",
                tipo_occupazione: {
                    categoria: "",
                    sottocategoria: ""
                },
                caratteristiche_occupazione: {
                    tipologia: ""
                },
                luogo: {
                    indirizzo: ""
                },
                dimensioni: {
                    lunghezza: "",
                    altezza: "",
                    profondita: "",
                    superficie: ""
                },
                periodo: {
                    giorno_e_ora_di_inizio: "",
                    giorno_e_ora_di_fine: ""
                },
                motivo: {
                    descrizione: ""
                },
                dichiaro: [],
                marca_da_bollo: {
                    numero: "",
                    data: ""
                }
            },
            cimitero: {
                arrivo: {
                    id: "",
                    value: ""
                },
                tipo_di_sepoltura: {
                    id: "",
                    value: ""
                },
                cimitero: {
                    id: "",
                    value: ""
                },
                casellario: {
                    id: "",
                    value: ""
                },
                loculo: {
                    id: "",
                    value: ""
                },
                durata: {
                    id: "",
                    value: ""
                }
            },
            accessi: {
                tipologia: "",
                modalita: "",
                indirizzo_spedizione: "",
                motivo: "",
                dichiaro: [],
                chiede: {
                    informazione: "",
                    documento: "",
                    dato: ""
                }

            },
            parcheggio: {
                chiede: [],
                tipo_di_invalidita: [],
                vecchio_contrassegno: "",
                marca_da_bollo: {
                    numero: "",
                    data: ""
                }
            },
            passo_carrabile: {
                motivo: "",
                chiede: "",
                tipologia: "",
                provvedimento: {
                    numero: "",
                    data: ""
                },
                luogo: {
                    indirizzo: ""
                },
                dimensioni: {
                    lunghezza: "",
                    profondita: "",
                    superficie: ""
                },
                immobile: {
                    foglio: "",
                    particella: "",
                    subalterno: "",
                    categoria: ""
                }
            },
            bonus: {
                tipologia: ""
            },
            fee: {

            },
            matrimonio: {
                tipo_matrimonio: "",
                data_matrimonio: "",
                marca_bollo_1: {},
                marca_bollo_2: {},
            },
            fatture: [],
            multa: {},
            taxBreaks: {},
            schoolBenefits: {}
        };

        const prefs = Array.isArray(data.preferenze) || data.preferenze === undefined ? defaultPref : data.preferenze;

        let isStopped = false;

        if (data?.richiedente) {
            if (Array.isArray(data.richiedente) && data.richiedente?.[0]) {
                if (action.payload?.code === "RPM" && !data.richiedente[0].anagrafica.hasOwnProperty("stato_civile")) {
                    isStopped = true;
                } else {
                    for (let i of Object.keys(data.richiedente[0])) {
                        for (let y of Object.keys(data.richiedente[0][i])) {
                            if (!data.richiedente[0][i][y]) {
                                isStopped = true;
                                break;
                            }
                        }
                        if (isStopped)
                            break;
                    }
                }
            }
            else
                isStopped = true
        }
        else if (data?.type_richiedente) {
            for (let i of Object.keys(data?.type_richiedente[0])) {
                for (let y of Object.keys(data?.type_richiedente[0][i])) {

                    if (!data?.type_richiedente[0][i][y] && !y.includes("pratica")
                        && !y.includes("planimetrico")) {
                        isStopped = true;
                        break;
                    }
                }
                if (isStopped)
                    break;
            }
        }

        console.log("draft data ", data);
        state.data = {
            richiedente: data.richiedente,
            richiedente_2: data.richiedente_2,
            beneficiario: data.beneficiario?.["0"] ? [data.beneficiario?.["0"]] : data.beneficiario,
            disabile: data.disabile,
            isee: data.isee,
            savedBen: data.savedBen,
            savedDis: data.savedDis,
            type_richiedente: data.type_richiedente,
            preferenze: prefs,
            richiesta: data.richiesta,
            "autore_della_segnalazione": data.autore_della_segnalazione,
            bonus: {
                richiedente: data?.richiedente || [],
                credito: data?.credito || {},
                isee: data?.isee || {},
                nucleo_familiare: data?.nucleo_familiare || [],
                dichiaro: data?.dichiaro || [],
                consapevole: data?.consapevole || [],
                costi: data?.costi || {},
                marca_bollo: data?.marca_bollo || {},
                requisiti: data?.requisiti || {},
            },
            fee: {
                richiedente: data?.richiedente || [],
                code: data?.code || "",
                fatture: data?.fatture || {},
                pagamento: data?.pagamento || {}
            },
            matrimonio: {
                richiedente: data?.richiedente || [],
                richiedente_2: data?.richiedente_2 || [],
                marca_bollo: data?.marca_bollo || {}
            },
            access: {
                richiedente: data?.richiedente || [],
                delegato: data?.delegato || [],
            },
            towZone: {
                richiedente: data?.richiedente || [],
            },
            disservizio: data?.disservizio,
            ufficio: {
                ufficio: data.ufficio,
                appuntamenti_disponibili: data?.appuntamenti_disponibili,
                disponibilita: data?.disponibilita,
                motivo: data?.motivo,
                dettagli: data.dettagli,
                richiedente: data.richiedente
            },
            imu: {
                contribuente: data?.contribuente,
                coobbligato: data?.coobbligato,
                immobili: data?.immobili
            },
            cimitero: {
                concessionario: data?.concessionario,
                defunto: data?.defunto
            },
            fatture: data?.fatture,
            error: data.error,
            stopped: isStopped,
            multa: data?.multa,
            taxBreaks: {
                richiedente: data?.richiedente || [],
                immobili: data?.immobili || data?.immobili_dat || [],
                nucleo_familiare: data?.nucleo_familiare || [],
                ...(data?.preferenze?.taxBreaks || {})
            },
            schoolBenefits: {
                richiedente: data?.richiedente || [],
                beneficiario: data?.beneficiario?.length ? data?.beneficiario : [],
                credito: data?.credito || {},
                savedBen: data?.savedBen ? data?.savedBen : (data?.beneficiario?.length ? [data?.beneficiario?.[0]] : (data?.beneficiario ? [data?.beneficiario] : [])),
                documenti: data?.documenti || {},
                ...(data?.preferenze?.schoolBenefits || {})
            }
        }

    } else {
        const inData = action.payload?.data?.data;

        let isStopped = false;

        if (inData?.richiedente) {
            if (Array.isArray(inData.richiedente)) {

                for (let i of Object.keys(inData?.richiedente[0])) {
                    for (let y of Object.keys(inData?.richiedente[0][i])) {

                        if (!inData?.richiedente[0][i][y]) {

                            isStopped = true;
                            break;
                        }
                    }
                    if (isStopped)
                        break;
                }
            }
        } else if (inData?.type_richiedente) {
            for (let i of Object.keys(inData?.type_richiedente[0])) {
                for (let y of Object.keys(inData?.type_richiedente[0][i])) {
                    if (!inData?.type_richiedente[0][i][y] && !y.includes("pratica")
                        && !y.includes("planimetrico")) {

                        isStopped = true;
                        break;
                    }
                }
                if (isStopped)
                    break;
            }
        } else if (inData?.imu?.contribuente) {
            if (Array.isArray(inData?.imu?.contribuente)) {
                for (let i of Object.keys(inData?.imu?.contribuente[0])) {
                    for (let y of Object.keys(inData?.imu?.contribuente[0][i])) {

                        if (!inData?.imu?.contribuente[0][i][y]) {

                            isStopped = true;
                            break;
                        }
                    }
                    if (isStopped)
                        break;
                }
            }
        }

        if (inData) {
            state.data.stopped = isStopped;
            state.data.beneficiario = inData.beneficiario ? inData.beneficiario : state.data.beneficiario;
            state.data.richiedente = inData.richiedente ? inData.richiedente : state.data.richiedente;
            state.data.richiedente_2 = inData.richiedente_2 ? inData.richiedente_2 : state.data.richiedente_2;
            state.data.isee = inData.isee ? inData.isee : state.data.isee;
            state.data.autore_della_segnalazione = inData.autore_della_segnalazione ? inData.autore_della_segnalazione : state.data.autore_della_segnalazione;

            state.data.test_contribuente = inData.generico?.giuridico?.contribuente || [];
            state.data.test_type = inData.generico?.giuridico?.type_richiedente || [];

            state.data.imu = inData.imu ? inData.imu : state.data.imu;

            if (inData?.last_type?.includes("giuridica")) {
                state.data.type_richiedente = inData?.generico?.giuridico?.type_richiedente;
            } else
                state.data.type_richiedente = inData.type_richiedente ? inData.type_richiedente : state.data.type_richiedente;
        }
    }
}

//used to filter customers by TAXID
function filtering(id, state, action) {
    if (!Array.isArray(state.data[id])) {
        for (let i of state.data['imu'][id]) {
            if (i.anagrafica.codice_fiscale === action.payload?.taxID)
                i.selected = true;
            else
                i.selected = false;
        }
        return;
    }


    for (let i of state.data[id]) {
        if (i.anagrafica.codice_fiscale === action.payload?.taxID)
            i.selected = true;
        else
            i.selected = false;
    }
}

export default stepSlice;