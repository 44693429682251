import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import CustomerFormBox from "../../../UI-component/registryForms/CustomerFormBox";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import Loader from "../../../UI-component/Loader";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import PopupTextField from "../../../UI-component/ui-el/PopupTextField";
import { onFileSet2 } from "../../../util/Dynamics";
import IseeFormBox from "../../../UI-component/registryForms/IseeFormBox";
import FamilyFormBox from "../../../UI-component/registryForms/FamilyFormBox";
import { getTenantCode } from "../../../util";
import CheckedFormBox from "../../../UI-component/CheckedFormBox";
import SelectionFormBox from "../../../UI-component/SelectionFormBox";
import { useService } from "../../../classes/useService";
import CustomerPropertyFormBox from "../../../UI-component/registryForms/CustomerPropertyFormBox";
import PopupSelectField from "../../../UI-component/ui-el/PopupSelectField";
import { SchoolBenefitsService } from "../../../classes/schoolBenefits/SchoolBenefitsService";

function Specific(props) {
  const iseeRequired = useSelector(() => true);
  const isStopped = useSelector(state => state.stepSlice.data.stopped);

  // console.log("props", props);

  const {data, loading, error, values, save, savePrefs, update, updateField, isValidStep} = useService(SchoolBenefitsService, props.save, props?.info?.data || {});

  const [files, setFiles] = useState({
    iban: data?.credito?.iban
  });

  const fields = useMemo(() => {
    switch(data?.tipologia){
      case values?.types?.[0]?.value:
        return ["spese_sostenute", "certificato_iscrizione_frequenza",]
      case values?.types?.[1]?.value:
        return ["contratto_locazione", "pagamento_canone", "certificato_iscrizione_frequenza"]
      case values?.types?.[2]?.value:
        return ["iscrizione_corso_laurea", "autocertificazione_frequenza", "codice_fiscale"]
    }

    return []
  }, [data?.tipologia])

  const getInitialDocuments = (fields, data) => {
    return fields.reduce((acc, key) => {
      acc[key] = data?.documenti?.[key] || null;
      acc[`base64_${key}`] = data?.documenti?.[`base64_${key}`] || null;
      return acc;
    }, {});
  };

  const [documents, setDocuments] = useState({
    documenti: getInitialDocuments(fields, data)
  });

  const [familyMembers, setFamilyMembers] = useState([]);

  const onNext = () => {
    savePrefs()
    props.next();
  }

  const getInfoList = () => {
    const value = data?.tipologia;
    const defaultList = [
      { element: "Richiedente", id: "#report-author" },
      { element: "Beneficiario", id: "#report-author" },
      { element: "Documenti", id: "#report-author" },
      { element: "Dati per l'accredito", id: "#report-author" },
    ]
    switch(value){
      case values?.types?.[0]?.value:
        return [
          ...defaultList,
          { element: "Categoria", id: "#report-author" },
          { element: "Dichiaro", id: "#report-author" },
        ]
      case values?.types?.[1]?.value:
        return [
          ...defaultList,
          { element: "Categoria", id: "#report-author" }
        ]
      case values?.types?.[2]?.value:
        return [
          ...defaultList,
          { element: "Studenti al terzo anno delle scuole secondarie di primo grado", id: "#report-author" },
          { element: "Studenti delle scuole secondarie di secondo grado", id: "#report-author" },
          { element: "Studenti universitari", id: "#report-author" },
        ]
      default:
        return defaultList
    }
  }

  const onCreditChange = (e) => {
    update({
      id: "credito",
      internalId: e.target.id,
      data: e.target.value
    })
  }

  const onDocumentChange = (newDocuments) => {
    console.log("documents", newDocuments)
  }

  useEffect(() => {
    if(files?.["iban"] !== data?.credito?.iban){
      update([
        {
          id: "credito",
          internalId: "base64_iban",
          data: files?.["base64_iban"]
        },
        {
          id: "credito",
          internalId: "iban",
          data: files?.["iban"]
        }
      ])
    }
  }, [files])

  useEffect(() => {
    // console.log("documents", documents)
    const docs = documents?.documenti || {};

  
    const updates = [];
  
    fields.forEach(field => {
      if (docs?.[field] !== data?.documenti?.[field]) {
        updates.push(
          {
            id: "documenti",
            internalId: `base64_${field}`,
            data: docs?.[`base64_${field}`]
          },
          {
            id: "documenti",
            internalId: field,
            data: docs?.[field]
          }
        );
      }
    });
  
    if (updates.length > 0) {
      update(updates);
    }
  
  }, [documents]);


  useEffect(() => {
    update({
      id: "documenti",
      data: {...documents?.documenti}
    }, true)
  }, [])

  const renderCreditInfo = () => {
    return(
      <SummaryCard
        type="Dati per l'accredito*"
        description={"Inserisci i dati per l'accredito"}>
        <PopupTextField
          defaultValue={data?.credito?.intestatario}
          fieldType="text"
          id="intestatario"
          fieldTitle="Intestatario*"
          description="Inserisci l'intestatario"
          max="18"
          onChange={onCreditChange}
        />
        <PopupTextField
          defaultValue={data?.credito?.banca}
          fieldType="text"
          id="banca"
          fieldTitle="Banca*"
          description="Inserisci il nome della banca"
          max="18"
          onChange={onCreditChange}
        />
        <PopupTextField
          defaultValue={data?.credito?.filiale}
          fieldType="text"
          id="filiale"
          fieldTitle="Filiale*"
          description="Inserisci la filiale"
          max="18"
          onChange={onCreditChange}
        />
        <PopupTextField
          defaultValue={data?.credito?.indirizzo}
          fieldType="text"
          id="indirizzo"
          fieldTitle="Indirizzo*"
          description="Inserisci l'indirizzo"
          max="18"
          onChange={onCreditChange}
        />
        <PopupTextField
          defaultValue={data?.credito?.codice_iban}
          fieldType="text"
          id="codice_iban"
          fieldTitle="Codice IBAN*"
          description="Inserisci il codice IBAN"
          max="34"
          min="34"
          straightErr={error?.credito?.codice_iban}
          onChange={onCreditChange}
        />

        <PopupTextField
          onChange={(e) => { onFileSet2(e, setFiles) }}
          fieldType="file"
          id="iban"
          defaultValue={data?.credito?.iban || ""}
          fieldTitle="Documento IBAN*"
          description="Allegare il documento IBAN" />

      </SummaryCard>
    )
  }


  const renderAlerts = () =>  {
    const value = data?.tipologia;
    const defaultAlerts = <>
      <ErrorAlert errors={[{ name: "Richiedente", id: "#isee-info" }]} err={error.richiedente || isStopped} />
      <ErrorAlert errors={[{ name: "Dati per l'accredito", id: "#isee-info" }]} err={error.credito} />
    </>
    switch(value) {
      case values?.types?.[0]?.value:
        <>
          {defaultAlerts}
          
          {/* <ErrorAlert errors={[{ name: "Dati per l'accredito", id: "#isee-info" }]} err={error.credito} /> */}
        </>
      // case "CAP":
      //   return(
      //     <>
      //       <ErrorAlert errors={[{ name: "Richiedente", id: "#richidente" }]} err={error.richiedente || isStopped} />
      //       <ErrorAlert errors={[{ name: "Dati per l'accredito", id: "#credito" }]} err={error.credito} />
      //       <ErrorAlert errors={[{ name: "Costi", id: "#costi" }]} err={error.costi} />
      //       <ErrorAlert errors={[{ name: "Requisiti", id: "#requisiti" }]} err={error.requisiti} />
      //       <ErrorAlert errors={[{ name: "Marca da bollo", id: "#dichiaro" }]} err={error.marca_bollo} />
      //       <ErrorAlert errors={[{ name: "Dichiaro", id: "#dichiaro" }]} err={error.dichiaro} />
      //     </>
      //   )
      default: 
        return defaultAlerts
    }
  }

  const renderDocument = (field, title, description) => {
    return (
      <PopupTextField
        key={`document-${field}`}
        onChange={(e) => { onFileSet2(e, setDocuments) }}
        fieldType="file"
        id={field}
        defaultValue={data?.documenti?.[field] || ""}
        fieldTitle={title}
        description={description} />
    )
  }

  const renderDocuments = () => {
    const value = data?.tipologia;
    switch(value){
      case values?.types?.[0]?.value:
        return (
          <>
            {renderDocument("spese_sostenute", "Spese sostenute", "i biglietti di viaggio o i relativi abbonamenti utilizzati nell’anno scolastico/accademico di riferimento o idonea documentazione attestante le spese sostenute")}
            {renderDocument("certificato_iscrizione_frequenza", "Certificato iscrizione*", "Certificato di regolare iscrizione e frequenza per l’anno scolastico/accademico di riferimento")}
          </>
        )
      case values?.types?.[1]?.value:
        return (
          <>
            {renderDocument("contratto_locazione", "Contratto locazione*", "Copia del contratto di locazione debitamente registrato, di durata non inferiore a otto mesi, per l’anno scolastico/accademico di riferimento")}
            {renderDocument("pagamento_canone", "Pagamento canone*", "la documentazione attestante il pagamento del canone")}
            {renderDocument("certificato_iscrizione_frequenza", "Certificato iscrizione*", "Certificato di regolare iscrizione e frequenza per l’anno scolastico/accademico di riferimento")}
          </>
        )
      case values?.types?.[2]?.value:
        return (
          <>
            {renderDocument("autocertificazione_frequenza", "Autocertificazione frequenza", "per gli studenti della scuola secondaria di secondo grado: autocertificazione circa la regolare frequenza alle lezioni e i voti finali conseguiti in tutte le materie curricolari nell’anno scolastico oggetto del bando")}
            {renderDocument("iscrizione_corso_laurea", "Attestazione iscrizione corso di laurea", "per gli studenti universitari: attestazione della segreteria di facoltà da cui risulti l’iscrizione al corso di laurea e la votazione conseguita nell’esame finale")}
            {renderDocument("codice_fiscale", "Codice fiscale*", "codice fiscale del richiedente")}
            
          </>
        )
      default:
        return null;
    }

  }

  const renderContentByType = () => {
    const value = data?.tipologia;
    switch(value){
      case values?.types?.[0]?.value:
        return (
          <>
            <SelectionFormBox
              showTitle={`Categoria*`}
              title={"categoria"}
              description={" "}
              choices={values.categories}
              defaultValue={data?.categoria}
              classDefaultValue={""}
              oKey={""}
              update={update}
              disabled={"  " === "" ? "disabled" : ""} />

            <CheckedFormBox
              type="checkbox"
              singleChoice={true}
              title={["Agevolazioni scolastiche", "Dichiaro"]}
              description=""
              choices={values?.declarations || []}
              defaultValue={data?.dichiaro || []}
              update={update}
            />
          </>
        )
      case values?.types?.[1]?.value:
        return (
          <>
            <SelectionFormBox
              showTitle={`Categoria*`}
              title={"categoria"}
              description={" "}
              choices={values.categories}
              defaultValue={data?.categoria}
              classDefaultValue={""}
              oKey={""}
              update={update}
              disabled={"  " === "" ? "disabled" : ""} />
          </>
        )
      case values?.types?.[2]?.value:
        return (
          <>
            <CheckedFormBox
              type="checkbox"
              singleChoice={false}
              title={["Agevolazioni scolastiche", "DICHIARO 1"]}
              customTitle={"STUDENTI AL TERZO ANNO DELLE SCUOLE SECONDARIE DI PRIMO GRADO"}
              description=""
              required={false}
              choices={values?.declarations_1 || []}
              defaultValue={data?.dichiaro_1 || []}
              update={update}
            />
            <CheckedFormBox
              type="checkbox"
              singleChoice={false}
              title={["Agevolazioni scolastiche", "DICHIARO 2"]}
              customTitle={"STUDENTI DELLE SCUOLE SECONDARIE DI SECONDO GRADO"}
              required={false}
              description=""
              choices={values?.declarations_2 || []}
              defaultValue={data?.dichiaro_2 || []}
              update={update}
            />
            <CheckedFormBox
              type="checkbox"
              singleChoice={false}
              required={false}
              title={["Agevolazioni scolastiche", "DICHIARO 3"]}
              customTitle={"STUDENTI UNIVERSITARI"}
              
              description=""
              choices={values?.declarations_3 || []}
              defaultValue={data?.dichiaro_3 || []}
              update={update}
            />
          </>
        )
      default:
        return null;
    }

  }

  return (
    <div className="row">
      <SideList
        infoList={getInfoList()} />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            {renderAlerts()}
            <Loader loading={loading} fixedPosition/>
            <CustomerFormBox id="richiedente"
              cards={data?.richiedente?.length > 0 ? [data?.richiedente] : []}
              type={false}
              description="Informazione su di te"
              title="Richiedente"
              service={"DAS"}
              update={update}
              prefData={props.info.data?.richiedente}
            />

            <CustomerFormBox
              id={"Beneficiario"}
              cards={data?.beneficiario?.length > 0 ? [data?.beneficiario] : []}
              description={"Informazioni sulla persona che utilizzerà il servizio, da compilare solo se diversa dal richiedente"}
              type={false}
              title={"Beneficiario"}
              service={"DAS"}
              update={update}
              savedBen={data?.savedBen || []}
              // prefData={props.info.data?.beneficiario}
            />

            <SummaryCard
              type="Documenti*"
              description={"Inserisci i documenti"}>
              {renderDocuments()}
            </SummaryCard>

            {renderCreditInfo()}
            {renderContentByType()}
            {/* <IseeFormBox
              id={"isee"}
              description={"Informazioni sul valore dell’ISEE del tuo nucleo familiare basato sulla tua ultima dichiarazione"}
              optionalTitle={false}
              cards={data?.isee?.length > 0 ? data?.isee : []}
              update={update}
              placeholderData={props.info?.data?.isee}
              required={iseeRequired}
            />

            
            {renderExtraContent()} */}

          </div>
        </div>
        <NextBtnForm
          next={onNext}
          back={props.back}
          noSave={false}
          saveRequest={save}
          // disabled={error.richiedente || error.credito || error.isee || error.nucleo_familiare || isStopped} />
          disabled={!isValidStep("specific")} />

        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  )
}


export default Specific;