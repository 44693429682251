import React, { useEffect, useState } from "react";
import CustomerCardHeader from "../registryForms/CustomerCardHeader";
import CustomerCard from "../registryForms/CustomerCard";
import { AddConsumerPopup } from "../AddingForms/AddConsumerPopup";
import { uniqueIdBuilder } from "../../util/Dynamics";

function CustomerFormBox(props) {
    const [cards, setCards] = useState(props.cards);
    const [show, setShow] = useState(false);
    const [typeData, setTypeData] = useState(props?.typeData || "");

    const setType = (type) => {
        setTypeData(type);
    }

    const addCustomer = () => {
        setShow(true);
    }

    const close = () => {
        setShow(false);
    }

    const onRemove = (e, data) => {

        let idx = null;

        for (let i of cards) {
            if (i) {
                idx = i.findIndex((el) => {
                    return el.anagrafica.codice_fiscale === data.anagrafica.codice_fiscale;
                });

                if (idx)
                    break;
            }
        }


        //TODO: FIX ONLY ONE GOTTA BE REMOVED
        setCards(prev => {
            const obj = structuredClone(prev);
            obj[0].splice(idx, 1);
            prev = obj;

            return [...prev]
        });

        props.update({
            id: props.id,
            data: [cards[0]],
            removing: true
        });
    }

    const onBenChange = (e) => {
        console.log("props.savedBen", props.savedBen)
        const ben = props.savedBen.filter(itm => {
            return itm?.anagrafica?.codice_fiscale === e.target.id;
        })

        // console.log("on ben change", ben?.length ? ben?.[0] : ben);
        setCards([ben?.length ? ben?.[0] : ben]);

        props.update({
            id: props.id,
            data: [ben?.length ? ben?.[0] : ben],
            change: true
        });
    }

    const onDisChange = (e) => {
        const dis = props.savedDis.filter(itm => {
            return itm.anagrafica?.codice_fiscale === e.target.id;
        })

        setCards([dis]);

        props.update({
            id: props.id,
            data: dis,
            change: true
        });
    }

    useEffect(() => {
        setCards(props.cards);
    }, [props.cards]);

    useEffect(() => {
        // console.log("customer saved ben", props.savedBen)
    }, [props.savedBen])


    return (
        <>
            {
                !props.optionalTitle &&
                <section
                    className="it-page-section"
                    id={props.id}>
                    <div className="cmp-card mb-40">
                        {
                            show &&
                            <AddConsumerPopup
                                close={close}
                                id={props?.customId || props.title}
                                typeData={typeData}
                                service={props.service}
                                update={props.update}
                                prefData={props.prefData}
                                generic={props?.generic} />
                        }

                        <div className="card has-bkg-grey shadow-sm p-big">
                            <CustomerCardHeader
                                description={props.description}
                                type={props.type}
                                types={props.types}
                                customTitle={props?.customTitle}
                                title={props.title}
                                defaultValue={props?.cards?.[0]?.[0]?.anagrafica?.type || props?.prefData?.[0]?.type?.type || props?.tp || typeData}
                                disabled={cards?.length > 0}
                                setType={setType}
                                generic={props?.generic ?? []} />
                            <div className="card-body p-0">
                                {
                                    props?.id.toLowerCase() === "beneficiario" &&
                                    <div>
                                        <p className="subtitle-small mb-0">{"Seleziona la persona beneficiaria del servizio tra quelle salvate:"}</p>

                                        {
                                            props.savedBen.map((itm) => {
                                                if (cards.length <= 0) {
                                                    return <input
                                                        id={itm?.anagrafica?.codice_fiscale}
                                                        className={`benBtn`}
                                                        type="button" value={itm?.anagrafica?.nome}
                                                        onClick={onBenChange}
                                                        key={itm?.anagrafica?.codice_fiscale} />
                                                } else {
                                                    return <input
                                                        id={itm?.anagrafica?.codice_fiscale}
                                                        className={`benBtn ${cards[0][0]?.anagrafica?.codice_fiscale === itm?.anagrafica?.codice_fiscale ? "chosen" : ""}`}
                                                        type="button" value={itm?.anagrafica?.nome}
                                                        onClick={onBenChange}
                                                        key={itm?.anagrafica?.codice_fiscale} />
                                                }
                                            })
                                        }

                                    </div>
                                }
                                {
                                    props?.lilErr && props?.id.toLowerCase() === "beneficiario" &&
                                    <p style={{ color: "red", marginTop: "5px", fontSize: "14px" }} className="subtitle-small mb-0">{"Codice fiscale già in utilizzo"}</p>
                                }

                                {
                                    props?.id.toLowerCase() === "disabile" &&
                                    <div>
                                        <p className="subtitle-small mb-0">{"Seleziona la persona disabile del servizio tra quelle salvate:"}</p>

                                        {
                                            props.savedDis.map(itm => {
                                                if (cards.length <= 0) {
                                                    return <input
                                                        id={itm?.anagrafica?.codice_fiscale}
                                                        className={`benBtn`}
                                                        type="button" value={itm?.anagrafica?.nome}
                                                        onClick={onDisChange}
                                                        key={itm?.anagrafica?.codice_fiscale} />
                                                } else {
                                                    return <input
                                                        id={itm?.anagrafica?.codice_fiscale}
                                                        className={`benBtn ${cards[0][0]?.anagrafica?.codice_fiscale === itm?.anagrafica?.codice_fiscale ? "chosen" : ""}`}
                                                        type="button" value={itm?.anagrafica?.nome}
                                                        onClick={onDisChange}
                                                        key={itm?.anagrafica?.codice_fiscale} />
                                                }
                                            })
                                        }

                                    </div>
                                }

                                {
                                    props?.lilErr && props?.id.toLowerCase() === "disabile" &&
                                    <p style={{ color: "red", marginTop: "5px", fontSize: "14px" }} className="subtitle-small mb-0">{"Codice fiscale già in utilizzo"}</p>
                                }

                                {
                                    cards !== undefined &&
                                    cards.map((card) => {
                                        if (card && card.length > 0) {
                                            return <CustomerCard
                                                info={card}
                                                key={card[0]?.anagrafica?.codice_fiscale || uniqueIdBuilder()}
                                                title={props.title}
                                                id={props.id}
                                                service={props.service}
                                                onRemove={onRemove}
                                                typeData={typeData}
                                                update={props.update}
                                            />
                                        }
                                    })
                                }


                                {
                                    (cards?.length <= 0 || props?.id.toLowerCase() === "beneficiario" || props?.id.toLowerCase() === "disabile") &&
                                    <button type="button" className="btn plus-text mt-20 p-0"
                                        onClick={addCustomer} disabled={props?.type && !typeData}>
                                        <span className="rounded-icon">
                                            <svg className="icon icon-primary icon-sm" aria-hidden="true">
                                                <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-plus-circle" />
                                            </svg>
                                        </span>
                                        <span>Aggiungi {props?.customBtnTitle || props?.id.toLowerCase()}</span>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}


export default CustomerFormBox;