import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../context/StepsContext";

export const useService = (serviceClass, parentSaveFunc, config, parentSendFunc) => {
    /********************* hooks go down here *********************/
    const SERVICE_ID = serviceClass?.SERVICE_ID;
    const SERVICE_INTERNAL_ID = serviceClass?.SERVICE_INTERNAL_ID;
    const ctxService = useSelector(state => state.stepSlice.data?.[SERVICE_INTERNAL_ID]);
    const dispatcher = useDispatch();
    const service = useRef(serviceClass ? new serviceClass(ctxService) : {})
    const prefs = service?.current?.getPrefs();
    // console.log("ctx bonus", ctxService);
    /********************* state go down here *********************/
    const [data, setData] = useState({
        ...(service?.current?.data ? {
            ...(service?.current?.data.preferenze?.[SERVICE_INTERNAL_ID] ? service?.current?.data.preferenze?.[SERVICE_INTERNAL_ID] : {}),
            ...service?.current?.data,
        } : {})
    });
    const values = useMemo(() => ({
        ...(service?.current?.values ? service?.current?.values : {})
    }), [service?.current?.values])
    const [error, setError] = useState({
        ...(service?.current?.error ? service?.current?.error : {})
    });
    const [loading, setLoading] = useState(false);

    // console.log("service", data, values)

    /********************* methods go down here *********************/
    ////////////////////// get functions //////////////////////

    const getPrefs = () => {
        return service?.current?.getPrefs();
    }
    ////////////////////// validate functions //////////////////////
    const isValidStep = (stepId) => {
        return service?.current?.isValidStep ? service?.current?.isValidStep(stepId) : true 
    }

    const checkErrors = () => {
        const newError = service?.current?.checkErrors();
        // console.log("new errors", newError)
        setError(newError);
    }
    ////////////////////// UPDATE //////////////////////
    const update = (newData) => {
        // console.log("///////////// update", newData);
        const updatedData = service?.current?.update(newData);
        // console.log("useMarriage update", newData, typeof newData === "object", Array.isArray(newData));
        setData({
            ...data,
            ...updatedData
        })
    }

    const updateField = (e, fieldId, isInternal, customId) => {
        // console.log("///////////// updateField", e, fieldId, isInternal, customId);
        
        const updatedData = service?.current?.updateField(e, fieldId, isInternal, customId);
        setData({
            ...data,
            ...updatedData
        })
    }

    const updatePrefs = (newPreferences) => {
        const updatedData = service?.current?.updatePrefs(newPreferences);
        setData({
            ...data,
            ...updatedData
        })
    }
    ////////////////////// SAVE //////////////////////
    const savePrefs = () => {
        dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: SERVICE_INTERNAL_ID, data: {
            ...(service?.current?.getPrefs() || {})
        } }));
    }

    const save = async (isSendRequest) => {
        let saveData = service?.current?.getRequestData(isSendRequest);
        let isSend = typeof isSendRequest === "boolean" ? isSendRequest : false;
        // console.log("service?.current?.isValidData(isSend)", service?.current?.isValidData(isSend))
        if(service?.current?.isValidData(isSend) && ((parentSaveFunc && !isSend) || (parentSendFunc && isSend))){
            dispatcher(stepSlice.actions.dynamicSave({ id: SERVICE_ID, data: {...saveData} }));
            savePrefs();
            setLoading(true);
            if(isSend){
                await parentSendFunc(saveData);
            }
            else{
                await parentSaveFunc(saveData);
            }
            setLoading(false);
        }
    }

    /********************* useEffects go down here *********************/
    useEffect(() => {
        // let saveData = service?.current?.getRequestData(false);
        // console.log("useService", serviceClass?.SERVICE_ID, data, error);
        dispatcher(stepSlice.actions.dynamicSave({ id: SERVICE_ID, data: data || {} }));
        checkErrors();
    }, [data]);

    
    useEffect(() => {
        // console.log(`useService error`, serviceClass?.SERVICE_ID, error);
    }, [error])

    useEffect(() => {
        checkErrors();
    }, [])
    return {
        data,        // current data of the service request
        values,      // values to populate fields such as dropdowns
        loading,     // true = is saving request 
        error,       // error flag by data[id] eg. data["richiedente"] is invalid -> error.richiedente = true
        save,        // function to save the current service request
        savePrefs,   // function to save prefs into the store
        getPrefs,
        update,      // function to update the data object
        updateField,
        updatePrefs,
        isValidStep, // function to check <stepId> data -> useful for nextButton disable status
    }
}