import React from "react";
import PopupTextField from "../ui-el/PopupTextField";
import { onDataChange2, onFileSet2 } from "../../util/Dynamics";
import PopupBox from "../PopupBox";


function SchoolUiForm(props) {
  const prefData = props.prefData;
  const isBeneficiary = props?.id === "Beneficiario";
  const fillFields = !isBeneficiary || (isBeneficiary && props.isEdit)
  // console.log("props", props);

  const renderDocuments = () => {
    switch(props.service){
      case "DAS":
        return <>
          <PopupTextField
            onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
            defaultValue={props.defaultValue?.documenti?.spese_sostenute || prefData?.documenti?.spese_sostenute || ""}
            fieldType="file"
            id={"spese_sostenute"}
            fieldTitle={"I biglietti di viaggio o i relativi abbonamenti utilizzati nell’anno scolastico/accademico di riferimento o idonea documentazione attestante le spese sostenute"}
            // error={props.error}
          />

          <PopupTextField
            onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
            defaultValue={props.defaultValue?.documenti?.certificato_iscrizione_frequenza || prefData?.documenti?.certificato_iscrizione_frequenza || ""}
            fieldType="file"
            id={"certificato_iscrizione_frequenza"}
            fieldTitle={"Certificato di regolare iscrizione e frequenza per l’anno scolastico/accademico di riferimento*"}
            error={props.error}
          />
        </>
      case "DAS-2":
        return <></>
      case "DAS-3":
        return <></>

      default:
        return null;
    }
  }
  return (
    <PopupBox close={props.close} save={props.save} title={props?.id}>
      {
        props.error &&
        <p style={{ color: "red", fontWeight: "bolder" }}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
      }

      <PopupTextField
        disabled={!isBeneficiary}
        defaultValue={fillFields ? props?.defaultValue?.anagrafica?.nome : ""}
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="nome"
        fieldTitle="Nome*"
        description={" "}
        error={props.error}
      />
      <PopupTextField
        defaultValue={fillFields ? props?.defaultValue?.anagrafica?.cognome : ""}
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="cognome"
        fieldTitle="Cognome*"
        disabled={!isBeneficiary}
        description={" "}
        error={props.error}
      />
      <PopupTextField
        description={" "}
        defaultValue={fillFields ? props?.defaultValue?.anagrafica?.codice_fiscale : ""}
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="codice_fiscale"
        fieldTitle="Codice Fiscale*"
        disabled={!isBeneficiary}
        regex="^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]{1}$"
        error={props.error}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="date"
        id="data_di_nascita"
        fieldTitle="Data di nascita*"
        defaultValue={fillFields ? props?.defaultValue?.anagrafica?.data_di_nascita : ""}
        error={props.error}
        disabled={!isBeneficiary}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="luogo_di_nascita"
        fieldTitle="Luogo di nascita*"
        defaultValue={fillFields ? props?.defaultValue?.anagrafica?.luogo_di_nascita : ""}
        error={props.error}
        disabled={!isBeneficiary}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="residenza"
        fieldTitle="Indirizzo residenza*"
        defaultValue={fillFields ? props?.defaultValue?.indirizzi?.residenza: ""}
        error={props.error}
      />
      <PopupTextField
        onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
        fieldType="text"
        id="domicilio"
        fieldTitle="Comune residenza*"
        defaultValue={fillFields ? props?.defaultValue?.indirizzi?.domicilio: ""}
        error={props.error}
      />
      <>
        <PopupTextField
          onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
          fieldType="text"
          id="telefono"
          fieldTitle="Telefono*"
          defaultValue={fillFields ? props?.defaultValue?.contatti?.telefono : ""}
          regex="[0-9]{8}"
          error={props.error}
        />
        <PopupTextField
          onChange={(e) => { onDataChange2(e, props.setCustomerData) }}
          fieldType="email"
          id="email"
          fieldTitle="Email*"
          defaultValue={fillFields ? props?.defaultValue?.contatti?.email : ""}
          regex={`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`}
          error={props.error}
        />
      </>

      <PopupTextField
        onChange={(e) => { onFileSet2(e, props.setCustomerData) }}
        defaultValue={props.defaultValue?.documenti?.carta_di_identita || prefData?.documenti?.carta_di_identita || ""}
        fieldType="file"
        id={"carta_di_identita"}
        fieldTitle={"Carta di identità*"}
        error={props.error}
      />

      {/* {renderDocuments()} */}
    </PopupBox>





  )
}

export default SchoolUiForm;