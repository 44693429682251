import React, { useState, useEffect } from "react";
import StepHeader from "./StepHeader";
import { ServiceRequestAPI } from "../Apis/ServiceRequestAPI";
import { useSelector } from "react-redux";
import SuccessfullBox from "../UI-component/SuccessfullBox";
import CompactInfo from "./CompactInfo";
import Load from "./Loader";
import { PublicServiceRequestAPI } from "../Apis/PublicServiceRequestAPI";

function DynamicStepContent(props) {
    const ctx = useSelector(state => state.stepSlice.data);
    const [step, setStep] = useState({
        currentStep: 0,
    });
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [saved, setSaved] = useState(false);
    const [fail, setFail] = useState(false);
    const [link, setLink] = useState("");
    const [loading, setLoading] = useState(false);

    const [successData, setSuccessData] = useState({
        title: "",
        message: ""
    })

    //Object that dynamically filters the creation of the object 
    //sent to the backend 
    const services = new Map([
        [
            "rts", {
                ids: ["richiedente", "beneficiario", "isee", "preferenze", "savedBen"],
                files: [
                    ["richiedente", "carta_di_identita"],
                    ["beneficiario", "certificato_disabilita"],
                    ["savedBen", "certificato_disabilita"],
                    ["savedBen", "certificato_dieta_patologica"],
                    ["isee", "certificato_isee"]]
            },
        ],
        [
            "rms", {
                ids: ["richiedente", "beneficiario", "isee", "preferenze", "savedBen"],
                files: [
                    ["richiedente", "carta_di_identita"],
                    ["beneficiario", "certificato_disabilita"],
                    ["beneficiario", "certificato_dieta_patologica"],
                    ["savedBen", "certificato_disabilita"],
                    ["savedBen", "certificato_dieta_patologica"],
                    ["isee", "certificato_isee"]]
            }
        ],
        [
            "pti", {
                ids: ["imu", "contribuente", "coobbligato", "immobili"],
                files: [
                    ["imu", "contribuente", "carta_di_identita"]
                ]
            }
        ],
        [
            "pcl", {
                ids: ["richiedente", "fatture", "pagamento"],
                files: [
                    ["richiedente", "carta_di_identita"],
                ]
            }
        ],
        [
            "pcco", {
                ids: ["richiedente", "fatture", "pagamento", "fattura"],
                files: [
                    ["richiedente", "carta_di_identita"],
                ]
            }
        ],
        [
            "pco", {
                ids: ["richiedente", "fatture", "pagamento", "multa", "practice_number"],
                files: [
                    ["richiedente", "carta_di_identita"],
                ]
            }
        ],
        [
            "rpm", {
                ids: ["richiedente", "richiedente_2", "preferenze"],
                files: [
                    ["richiedente", "carta_di_identita"],
                    ["richiedente", "tessera_sanitaria"],
                    ["richiedente_2", "carta_di_identita"],
                    ["richiedente_2", "tessera_sanitaria"],
                ]
            }
        ],
        [
            "ssd", {
                ids: ['autore_della_segnalazione', "disservizio"],
                files: [
                    ["disservizio", "immagine1"],
                    ["disservizio", "immagine2"],
                    ["disservizio", "immagine3"],
                    ["disservizio", "immagine4"],
                    ["disservizio", "immagine5"],
                    ["disservizio", "immagine6"],
                    ["disservizio", "immagine7"],
                    ["disservizio", "immagine8"],
                    ["disservizio", "immagine9"],
                    ["disservizio", "immagine10"],
                ]

            }
        ],
        [
            "spa", {
                ids: ['ufficio', "appuntamenti_disponibili", "disponibilita", "motivo", "dettagli", "richiedente"],
                files: []
            }
        ],
        [
            "psp", {
                ids: ['type_richiedente', "preferenze"],
                files: [
                    ["type_richiedente", "carta_di_identita"],
                    ["type_richiedente", "schema_planimetrico"],
                    ["type_richiedente", "pratica_edilizia"],

                ]
            }
        ],
        [
            "rsd", {
                ids: ['concessionario', "defunto", "preferenze"],
                files: [
                    ["concessionario", "carta_di_identita"],
                    ["defunto", "certificato_di_morte"],
                ]
            }
        ],
        [
            "raat", {
                ids: ['richiedente', "delegato", "preferenze"],
                files: [
                    ["richiedente", "carta_di_identita"],
                ]
            }
        ],
        [
            "ppi", {
                ids: ['richiedente', "disabile", "preferenze", "savedDis"],
                files: [
                    ["richiedente", "carta_di_identita"],
                    ["richiedente", "attestazione_esercente"],
                    ["disabile", "carta_di_identita"],
                    ["disabile", "fototessera"],
                    ["disabile", "certificato_ufficio"],
                    ["disabile", "certificato_medico"],
                    ["disabile", "denuncia"],
                    ["savedDis", "carta_di_identita"],
                    ["savedDis", "fototessera"],
                    ["savedDis", "certificato_ufficio"],
                    ["savedDis", "certificato_medico"],
                    ["savedDis", "denuncia"],


                ]
            }
        ],
        [
            "dbe", {
                ids: ['richiedente', "credito", "isee", "nucleo_familiare", "dichiaro", "consapevole", "preferenze", "costi", "requisiti", "marca_bollo"],
                files: [
                    ["richiedente", "carta_di_identita"],
                    ["credito", "iban"],
                    ["isee", "certificato_isee"]
                ]
            }
        ],
        [
            "ppc", {
                ids: ['richiedente', "preferenze"],
                files: [
                    ["richiedente", "carta_di_identita"],
                ]
            }
        ],
        [
            "dat", {
                ids: ['richiedente', "immobili", "preferenze"],
                files: [
                    ["richiedente", "carta_di_identita"]
                ]
            }
        ],
        [
            "das", {
                ids: ['richiedente', "beneficiario", "credito", "documenti", "savedBen", "preferenze"],
                files: [
                    ["richiedente", "carta_di_identita"],
                    ["beneficiario", "carta_di_identita", "carta_di_identita_beneficiario"],
                    ["savedBen", "carta_di_identita"],
                    ["documenti", "spese_sostenute"],
                    ["documenti", "certificato_iscrizione_frequenza"],
                    ["documenti", "contratto_locazione"],
                    ["documenti", "pagamento_canone"],
                    ["documenti", "autocertificazione_frequenza"],
                    ["documenti", "iscrizione_corso_laurea"],
                    ["documenti", "codice_fiscale"],
                    ["credito", "iban"],
                ]
            }
        ],
    ]);

    const onNext = () => {
        setStep(prev => {
            return {
                currentStep: prev.currentStep + 1
            }
        });
    }

    const onBack = () => {
        setStep(prev => {
            return {
                currentStep: prev.currentStep > 0 ? prev.currentStep - 1 : prev.currentStep
            }
        });
    }

    //saves the request
    const saveRequest = async (data = null, isExt = false) => {
        try {
            const serviceData = await filterCtxByID(props.service, data);

            let res = {};

            if (!isExt) {
                res = await ServiceRequestAPI.sendServiceRequest(
                    {
                        service_data: {
                            service_id: props.service,
                            status: "Bozza",
                            data: {
                                ...serviceData
                            }
                        }
                    },
                    false
                );
            } else {
                res = await PublicServiceRequestAPI.sendServiceRequest(
                    {
                        service_data: {
                            service_id: props.service,
                            status: "Bozza",
                            data: {
                                ...serviceData
                            }
                        }
                    },
                    false
                );
            }


            if (!res.success) {
                setFail(true);
                if (props.service === "PTI")
                    reset(5000);
                else
                    reset(2000);
                return;
            }

            setSaved(true);
            reset(1000);
        } catch (err) {
            console.log(err);
            setFail(true);
            if (props.service === "PTI")
                reset(5000);
            else
                reset(2000);
        }
    }

    const filterCtxByID = async (service_id, data) => {
        const getFileFromUrl = async (url, fileName) => {
            const content = await fetch(url);
            const file = await content.blob();

            return new File([file], fileName);
        }

        const service_data = { data: {}, files: {} };


        const serving = services.get(service_id.toLowerCase());
        const dataset = data ?? ctx;

        const fileServing = serving?.files;
        const isGreater = serving?.files?.length === 0 ? false : serving?.files[0].length > 2;

        // console.log("save request", serving, isGreater, service_data);


        for (let i = isGreater ? 1 : 0; i < serving?.ids.length; i++) {
            // console.log("save request", serving.ids[i], Array.isArray(dataset[serving.ids[i]]))
            if (isGreater) {
                service_data.data[serving.ids[i]] = dataset[serving.ids[0]][serving.ids[i]];
            }
            else {
                if (typeof dataset[serving.ids[i]] === "object")
                    service_data.data[serving.ids[i]] = { ...dataset[serving.ids[i]] };
                else
                    service_data.data[serving.ids[i]] = dataset[serving.ids[i]];

            }
        }


        // console.log("save request", service_data)

        for (let id of fileServing) {
            let entity = {};
            const inCtx = isGreater ? structuredClone(dataset[id[0]][id[1]]) : structuredClone(dataset[id[0]]);


            if (inCtx?.length > 0 && id?.length > 0) {
                // console.log(inCtx, id);
                if (inCtx[0]) {

                    inCtx[0].masterType = id[0];
                    // console.log(inCtx);
                }
            }

            if (Array.isArray(inCtx)) {
                for (let y of inCtx) {

                    if (y?.selected)
                        entity = y;
                }
            } else
                entity = inCtx;

            const inID = isGreater ? 2 : 1;
            let customId;
            if(id?.[2]) customId = 2
            // console.log("fileserving", id, inCtx, id[inID]);


            if (entity) {
                if (entity?.documenti) {
                    if (entity.documenti[`base64_${id[inID]}`] && entity.documenti[`base64_${id[inID]}`].startsWith("blob")) {
                        // console.log(entity);
                        if(customId){
                            service_data.files[id[customId]] = await getFileFromUrl(entity.documenti[`base64_${id[inID]}`], entity.documenti[`${id[inID]}`]);
                        }
                        else if (entity?.masterType?.includes("_2")) {
                            service_data.files[id[inID] + "_2"] = await getFileFromUrl(entity.documenti[`base64_${id[inID]}`], entity.documenti[`${id[inID]}`]);
                        } else {
                            service_data.files[id[inID]] = await getFileFromUrl(entity.documenti[`base64_${id[inID]}`], entity.documenti[`${id[inID]}`]);
                        }
                    }
                }
                else {
                    if (entity[`base64_${id[inID]}`] && entity[`base64_${id[inID]}`].startsWith("blob")){
                        if(customId)
                            service_data.files[id[customId]] = await getFileFromUrl(entity[`base64_${id[inID]}`], entity[`${id[inID]}`]);
                        else
                            service_data.files[id[inID]] = await getFileFromUrl(entity[`base64_${id[inID]}`], entity[`${id[inID]}`]);
                    }
                }
            }
        }

        // console.log("service_data.files", service_data.files);

        return service_data;
    }

    //sends the request
    const send = async (data = null, isExt = false) => {
        try {
            setLoading(true);

            const serviceData = await filterCtxByID(props.service, data);

            let res = {};

            if (!isExt) {
                res = await ServiceRequestAPI.sendServiceRequest(
                    {
                        service_data: {
                            service_id: props.service,
                            status: "Inviato",
                            data: {
                                ...serviceData
                            }
                        }
                    },
                    false
                );
            } else {
                res = await PublicServiceRequestAPI.sendServiceRequest(
                    {
                        service_data: {
                            service_id: props.service,
                            status: "Inviato",
                            data: {
                                ...serviceData
                            }
                        }
                    },
                    false
                );
            }


            if (!res.success) {
                setFail(true);
                reset(5000);
                return;
            }

            if (props.service === "PTI") {
                setSuccess(true);
            }

            setSuccess(true);
            setLoading(false);

            if (res.data?.receipt)
                setLink(res.data.receipt);

            //set the success data
            setSuccessData(prev => {
                return { ...prev, title: res.data.title, message: res.data.message };
            })
            return res;
        } catch (err) {
            console.log(err);
            setFail(true);
            setLoading(false);
            reset(5000);
        }
    }

    //Retrieves the email of the user based on the given service
    const findEmail = () => {
        let email = "";
        switch (props.service) {
            case 'RTS':
                for (let i of ctx.richiedente) {
                    if (i?.selected)
                        email = i?.contatti?.email;
                }
                break;
            case 'RMS':
                for (let i of ctx.richiedente) {
                    if (i?.selected)
                        email = i?.contatti?.email;
                }
                break;
            case 'PTI':
                for (let i of ctx.imu?.contribuente) {
                    if (i?.selected)
                        email = i.contatti?.email;
                }
                break;
            case 'SSD':
                for (let i of ctx.autore_della_segnalazione) {
                    if (i?.selected)
                        email = i?.contatti?.email;
                }
                break;
            case 'SPA':
                email = ctx.ufficio?.richiedente?.email;
                break;
            case 'PSP':
                email = ctx.type_richiedente[0]?.contatti?.email;
                break;
            case "PCL":
                for (let i of ctx.richiedente) {
                    if (i?.selected)
                        email = i?.contatti?.email;
                }
                break;
            case "PCO":
                for (let i of ctx.richiedente) {
                    if (i?.selected)
                        email = i?.contatti?.email;
                } break;
            case "PPC":
                // console.log("ppc richiedente", ctx)
                email = ctx?.towZone?.richiedente?.[0]?.contatti?.email;
                break;
            case "RSD":
                for (let i of ctx.cimitero.concessionario) {
                    if (i?.selected)
                        email = i?.contatti?.email;
                }
                break;
            case "RAAT":
                for (let i of ctx.richiedente) {
                    if (i?.selected)
                        email = i?.contatti?.email;

                }
                break;
            case "PPI":
                for (let i of ctx.richiedente) {
                    if (i?.selected) {
                        if (i?.contatti)
                            email = i?.contatti?.email;
                        else
                            email = i["0"]?.contatti?.email;
                    }
                }
                break;
            case "DBE":
                email = ctx.bonus.richiedente?.[0]?.contatti?.email;
                break;
            case "DAT":
                email = ctx.taxBreaks?.richiedente?.[0]?.contatti?.email;
                break;
            case "DAS":
                email = ctx?.schoolBenefits?.richiedente?.[0]?.contatti?.email;
                break;
            case "RPM":
                // console.log(ctx);

                if (ctx.richiedente.length > 0 && !ctx.richiedente[0]?.contatti?.email)
                    email = ctx.richiedente?.[0]?.[0]?.contatti?.email
                else
                    email = ctx.richiedente?.[0]?.contatti?.email;

                break;
            default:
                break;
        }


        // console.log("email", email);

        setEmail(email);
    }

    useEffect(() => {
        findEmail();
    }, [ctx]);

    useEffect(() => {
        window.scroll(0, 0);
    }, [step])

    const reset = (time) => {
        setTimeout(() => {
            setFail(false);
            setSaved(false);
        }, time);
    }

    // console.log("ctx", ctx);

    return (
        <>
            {
                loading &&
                <div style={{ position: "fixed", zIndex: "100", top: "50%" }}>
                    <Load loading={loading} />
                </div>
            }

            {
                success ?
                    <SuccessfullBox
                        title={props.successTitle}
                        description={props.successDesc}
                        emailToSend={email}
                        service={props.service}
                        link={link}
                        successData={successData} /> :
                    <>
                        {
                            step.currentStep < props.steps.length &&
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10">
                                    <CompactInfo title={props.successTitle} description={props.successDesc} />
                                </div>
                            </div>
                        }

                        <StepHeader step={step?.currentStep} stepList={props.stepList} />

                        {
                            props.steps.map((itm, idx) => {
                                if (step.currentStep === idx) {
                                    if (typeof itm.component !== "function")
                                        return itm.component;

                                    if (itm?.info)
                                        return <itm.component info={itm.info}
                                            next={onNext} saveRequest={props.saveRequest}
                                            send={send}
                                            save={saveRequest}
                                            back={onBack}
                                            saved={saved}
                                            fail={fail}
                                            key={idx} />
                                    else
                                        return <itm.component info={itm.info}
                                            next={onNext} saveRequest={props.saveRequest}
                                            send={send}
                                            save={saveRequest}
                                            back={onBack}
                                            saved={saved}
                                            fail={fail}
                                            key={idx} />
                                }
                            })
                        }
                    </>
            }
        </>
    )
}


export default DynamicStepContent;